import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    teamStructureTemplate: {
      textDecoration: 'none',
      color: '#0363C4',
      display: 'block',
      marginTop: '5px',
      textAlign: 'center',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    wrapper: {
      textAlign: 'center',
    },
    dragDropWrapper: {
      margin: '24px auto 40px auto',
      width: '424px',
    },
  }),
);
