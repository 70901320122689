import { TeamStructureState } from '../../../stores/teamStructure/TeamStructureState';
import { supervisor } from './TeamStructure.mocks';

export const initialState: TeamStructureState = {
  errorToLoad: false,
  loading: false,
  done: true,
  teamStructure: null,
};

export const teamStructureStoreMock: TeamStructureState = {
  errorToLoad: false,
  loading: false,
  done: true,
  teamStructure: supervisor,
};

export const teamStructureStoreNotDoneMock: TeamStructureState = {
  errorToLoad: false,
  loading: false,
  done: false,
  teamStructure: supervisor,
};

export const teamStructureStoreMockEmpty: TeamStructureState = {
  errorToLoad: false,
  loading: false,
  done: true,
  teamStructure: null,
};

export const teamStructureStoreMockLoading: TeamStructureState = {
  errorToLoad: false,
  loading: true,
  done: false,
  teamStructure: supervisor,
};
