import { createStore } from 'effector';
import * as FileEvents from './FileEvents';
import FileState, { IFile } from './FileState';
import { initialState } from './FileStore.mocks';

const FileStore = createStore(initialState)
  .on(FileEvents.updateFile, (state: FileState, file: IFile) => ({
    ...state,
    file,
  }))
  .on(FileEvents.setIsProcessed, (state: FileState, isProcessed) => ({
    ...state,
    isProcessed,
  }))
  .reset(FileEvents.resetFileStore);

export default FileStore;
