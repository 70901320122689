import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    betaText: {
      fontFamily: 'Work sans',
      fontSize: '12px',
      fontWeight: 400,
      color: palette.secondary.main,
      textTransform: 'uppercase',
      marginLeft: '-3px',
    },
  }),
);
