import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    moreHorizontalButton: {
      position: 'absolute',
      right: '16px',
      top: '32px',
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#1AF07E',
      },
    },
    moreHorizontalButtonActive: {
      backgroundColor: 'rgba(0,0,0,0.15)',
    },
    listItem: {
      height: '64px',
      paddingLeft: '48px',
      paddingRight: '16px',
    },
  }),
);
