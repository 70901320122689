import palette from './palette';

export default {
  MuiLink: {
    root: {
      color: palette.text.secondary,
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '20px',
      textAlign: 'center',
      display: 'block',
      marginTop: '5px',
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: '16px !important',
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 16%) !important',
      width: '280px',
      minHeight: '64px !important',
      marginLeft: '-126px',
      marginTop: '4px',
    },
  },
  MuiListItemText: {
    primary: {
      color: 'rgba(0,0,0,0.9)',
      fontFamily: 'Work Sans',
      fontWeight: 400,
      fontSize: '16px',
      letterSpacing: '0.1px',
    },
  },
};
