import palette from './palette';

export default {
  h1: {
    fontFamily: 'Barlow',
    fontWeight: 600,
    textAlign: 'center',
    color: palette.primary.main,
    fontSize: '40px',
    letterSpacing: '0',
  },
  h2: {
    color: palette.primary.dark,
    fontFamily: 'Barlow',
    fontSize: '20px',
    fontWeight: 600,
    letterSpacing: '0',
    lineHeight: '24px',
    marginTop: 0,
    marginBottom: '8px',
  },
  subtitle1: {
    color: palette.primary.main,
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.1px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  subtitle2: {
    color: palette.primary.main,
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.1px',
    lineHeight: '20px',
  },
};
