import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export const appColors = {
  white: '#FFFFFF',
  black: {
    light: 'rgba(0,0,0,0.9)',
    dark: '#212121',
  },
  grey: {
    light: 'rgba(0,0,0,0.55)',
  },
  blue: {
    main: '#0363C4',
  },
};

const palette: PaletteOptions = {
  primary: {
    main: appColors.black.light,
    dark: appColors.black.dark,
  },
  secondary: {
    main: appColors.grey.light,
  },
  text: {
    secondary: appColors.blue.main,
  },
  background: {
    default: appColors.white,
  },
};

export default palette;
