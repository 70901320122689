import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';
import axios from '../../../config/axios/axiosInstance';
import { TeamStructure } from '../../../config/types';
import { getUserInfos } from '../../../config/utils/functions';
import { getApiHost, getV2EndPoint, getVendorIdParam } from '../../host/HostService';
import {
  GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE,
  GROW_ORGANIZATION_ACTIVATION,
} from '../../../config/featureToggles';

export default async function GetTeamStructure(): Promise<TeamStructure> {
  const log = useLogService();
  const { vendorId, orgId } = getUserInfos();

  const isMultiContractEnabled = isFeatureEnabled(GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE);
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const SERVICE = 'grow-team-structure-service';
  const PATH = 'grow-team-structure';
  const ENDPOINT = 'team-structure';
  const url = `${getApiHost()}/${SERVICE}`;
  let uri;
  let headers = {};

  const vendorIdParam = getVendorIdParam(vendorId, isMultiContractEnabled);

  if (isOrganizationEnabled) {
    uri = `${url}/v3/${ENDPOINT}`;
    headers = {
      orgId,
    };
  } else {
    uri = `${url}/${PATH}${getV2EndPoint(isMultiContractEnabled)}/${ENDPOINT}${vendorIdParam}`;
  }

  try {
    const response = await axios.get<TeamStructure>(uri, { headers });
    log.info(`Return GetTeamStructure from ${uri} to our model`, response);
    return response.data;
  } catch (error) {
    log.error(error);
    throw error;
  }
}
