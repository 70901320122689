import { createEffect } from 'effector';
import {
  executeCreateTeamStructure,
  executeUpdateTeamStructure,
} from '../../useCase/teamSructure/createAndUpdateTeamStructure';
import GetTeamStructure from '../../services/teamStructure/getTeamStructure/GetTeamStructure';
import GetTeamStructureBackupFile from '../../services/teamStructure/getTeamStructureBackupFile/GetTeamStructureBackupFile';

export const getTeamStructureEffect = createEffect({
  async handler() {
    return GetTeamStructure();
  },
});

/* istanbul ignore next */
export const createTeamStructureEffect = createEffect({
  async handler(csvfile: File) {
    return executeCreateTeamStructure(csvfile);
  },
});

/* istanbul ignore next */
export const updateTeamStructureEffect = createEffect({
  async handler(csvfile: File) {
    return executeUpdateTeamStructure(csvfile);
  },
});

/* istanbul ignore next */
export const getTeamStructureBackupFileEffect = createEffect({
  async handler(structureId: string) {
    return GetTeamStructureBackupFile(structureId);
  },
});
