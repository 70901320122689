import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      cursor: 'pointer',
      height: '192px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '16px',
    },
    containerBorderDefault: {
      border: '2px dashed rgba(0,0,0,0.15)',
    },
    containerBorderDrag: {
      border: '1px solid #047AF1',
    },
    inputFile: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  }),
);
