import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      width: '424px',
      position: 'relative',
    },
    title: {
      textAlign: 'left',
      fontSize: '32px',
      lineHeight: '40px',
    },
    subtitle: {
      marginTop: '16px',
      textAlign: 'left',
      width: '340px',
    },
    importedFileWrapper: {
      margin: '24px auto 40px auto',
      width: '424px',
    },
    buttonsGroup: {
      height: '48px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cancelButton: {
      marginRight: '16px',
      color: '#000000',
      fontWeight: 500,
      letterSpacing: 0,
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#1AF07E',
      },
    },
    importButton: {
      fontWeight: 500,
      letterSpacing: 0,
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        color: '#1AF07E',
      },
    },
  }),
);
