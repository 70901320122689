import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      position: 'relative',
      height: '192px',
      width: '192px',
      boxSizing: 'border-box',
      borderRadius: '16px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.08)',
    },
    wrapperLeader: {
      boxShadow: '0 4px 16px 0 rgba(0,0,0,0.24)',
      border: '1px solid #000000',
      marginTop: '16px',
      margin: '16px',
    },
    wrapperCursor: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    wrapperInvalidEmail: {
      border: '1px solid #C9201D',
    },
    tooltipDiv: {
      position: 'absolute',
      right: '7px',
      top: '7px',
      color: 'rgba(255,255,255,0.95)',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontWeight: 800,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'center',
    },
    alertOctagon: {
      color: '#C9201D',
    },
    avatarWrapper: {
      position: 'absolute',
      right: '64px',
      top: '16px',
      height: '64px',
      width: '64px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '32px',
      backgroundColor: '#F0ECFC',
    },
    avatarText: {
      height: '24px',
      width: '48px',
      color: '#212121',
      fontFamily: 'Barlow',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '24px',
      textAlign: 'center',
    },
    avatarYou: {
      borderRadius: '16px',
      backgroundColor: '#D2ADFF',
      color: '#000000',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '16px',
      textAlign: 'center',
      padding: '4px 6px',
    },
    avatarYouDiv: {
      margin: 'auto',
      width: 'fit-content',
      transform: 'translate(0, 64px)',
    },
    nameWrapper: {
      position: 'absolute',
      top: '88px',
      right: '16px',
      height: '48px',
      width: '160px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
    },
    nameText: {
      color: '#212121',
      fontFamily: 'Barlow',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '0',
      lineHeight: '24px',
      textAlign: 'center',
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
    roleWrapper: {
      position: 'absolute',
      top: '140px',
      right: '16px',
      height: '20px',
      width: '160px',
      color: 'rgba(0,0,0,0.55)',
      fontFamily: 'Work Sans',
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    chevronDown: {
      position: 'absolute',
      top: '165px',
      right: '84px',
    },
  }),
);
