export const getApiHost = (): string | undefined => '';

export function getVendorIdParam(vendorId: string | undefined, featureToggle = true): string {
  const queryParam = '?vendorId=';
  const vendorIdOrEmpty = vendorId ? `${queryParam}${vendorId}` : queryParam;
  return featureToggle ? vendorIdOrEmpty : '';
}

export function getV2EndPoint(featureToggle?: boolean): string {
  return featureToggle ? '/v2' : '';
}

export default getApiHost;
