import { useLogService } from 'admin-portal-shared-services';
import axios from '../../../config/axios/axiosInstance';
import { TeamStructure } from '../../../config/types';
import { getUserInfos } from '../../../config/utils/functions';
import { getApiHost } from '../../host/HostService';

const SERVICE = 'grow-team-structure-service';
const PATH_V3 = 'team-structure';
const ENDPOINT_V3 = 'upload';

export default async function createAndUpdateTeamStructureV3(
  csvFile: File,
): Promise<TeamStructure> {
  const log = useLogService();
  const { orgId } = getUserInfos();

  let headers = {};
  const url = `${getApiHost()}/${SERVICE}/v3/${PATH_V3}/${ENDPOINT_V3}`;
  headers = {
    orgId,
  };

  const formData = new FormData();
  formData.append('file', csvFile);

  try {
    const response = await axios.put<TeamStructure>(url, formData, { headers });

    log.info(`Return UpdateTeamStructure from ${url} to our model`, response);
    return response.data;
  } catch (error) {
    log.error(error);
    throw error;
  }
}
