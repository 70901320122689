import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      position: 'relative',
      boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,0.15)',
      height: '144px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: '16px',
    },
    taller: {
      height: '192px',
    },
    cancelButton: {
      position: 'absolute',
      right: '16px',
      top: '16px',
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#1AF07E',
      },
    },
    csvPaper: {
      height: '80px',
      width: 'auto',
    },
    content: {
      height: '82px',
      width: '280px',
      marginLeft: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    fileName: {
      fontWeight: 600,
      marginBottom: '4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      textAlign: 'left',
    },
    fileInfo: {
      color: palette.secondary.main,
    },
    progressMeterWrapper: {
      width: '100%',
      '& p': {
        margin: '8px 0px',
      },
      '& progress': {
        height: '4px',
      },
    },
  }),
);
