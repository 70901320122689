import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { IconButton } from '@admin-portal-shared-components/icon-button';
import { ArrowUp } from '@admin-portal-shared-components/icons';
import TeamStructureStore from '../../../../stores/teamStructure/TeamStructureStore';
import {
  capitalize,
  conditionalClass,
  getLocalStorageLeader,
  getUserInfos,
  removeLocalStorageLeader,
  setLocalStorageLeader,
} from '../../../../config/utils/functions';
import { TeamMember } from '../../../../config/types';
import { teamStructureUxButtonClicked } from '../../../../Analytics/index';
import { useAnalytics } from '../../../../hooks/useAnalytics/useAnalytics';
import TeamStructureContainerStyles from './TeamStructureContainer.styles';
import TeamStructureCard from '../TeamStructureCard/TeamStructureCard';
import { updateTeamStructureEffect } from '../../../../stores/teamStructure/TeamStructureEffects';

const TeamStructureContainer = (): JSX.Element => {
  const classes = TeamStructureContainerStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { userEmail } = getUserInfos();

  const { teamStructure, loading } = useStore(TeamStructureStore);
  const { structure } = teamStructure;

  const [leader, setLeader] = React.useState<TeamMember | null>(getLocalStorageLeader());
  const [team, setTeam] = React.useState<TeamMember[] | null>([]);

  const hasSubordinates = (email: string): boolean =>
    structure.filter((member) => member.reportTo === email).length > 0;

  useEffect(() => {
    if (loading) {
      setLeader(null);
      setTeam([]);
      removeLocalStorageLeader();
    }
  }, [loading]);

  useEffect(() => {
    const reportTo = leader ? leader.email : null;
    const newTeam = structure.filter((member: TeamMember) => member.reportTo === reportTo);
    newTeam.sort((a: TeamMember, b: TeamMember) => a.fullName.localeCompare(b.fullName));
    setTeam(newTeam);
    updateTeamStructureEffect.fail.watch(() => setTeam(newTeam));
  }, [leader, structure, updateTeamStructureEffect]);

  const handleLeaderChange = (newLeaderEmail: string | null) => {
    if (newLeaderEmail === null) {
      setLeader(null);
      removeLocalStorageLeader();
    }
    for (const member of structure) {
      if (member.email === newLeaderEmail) {
        setLeader(member);
        setLocalStorageLeader(member);
        return;
      }
    }
  };

  const handleClick = () => {
    dispatchGenericEvent(teamStructureUxButtonClicked, {
      button_name: 'upper-level',
      button_label: 'upper-level',
      screen_section: 'Single Member Card',
      hierarchy: capitalize(leader.positionName),
    });
    handleLeaderChange(leader.reportTo);
  };

  const renderArrowUpButton = () => (
    <div className={classes.arrowUpWrapper}>
      {!!leader && (
        <IconButton
          className={classes.arrowUpButton}
          variant="secondary"
          elevation
          icon={ArrowUp}
          size="large"
          onClick={handleClick}
          data-testid="arrowUpButton"
        />
      )}
    </div>
  );

  const renderLeader = () =>
    leader && (
      <TeamStructureCard
        person={leader}
        isYou={userEmail === leader.email}
        isLeader
        data-testid="ts-card-leader"
        reportTo={leader.reportTo}
        changeLeader={(newLeader) => handleLeaderChange(newLeader)}
      />
    );

  const renderTeam = (): JSX.Element | JSX.Element[] =>
    team.length > 0 ? (
      <div
        className={`
        ${classes.teamMembersWrapper}
        ${conditionalClass(!leader, classes.teamMembersWrapperTopLevel)}
      `}
        data-testid="teamMembersWrapper"
      >
        {leader && <div className={classes.triangleUp} data-testid="triangleUp" />}
        <div className={classes.teamMembersContainer}>
          {team.map((member) => (
            <TeamStructureCard
              person={member}
              key={member.email}
              data-testid="ts-card-member"
              isYou={userEmail === member.email}
              changeLeader={(newLeader) => handleLeaderChange(newLeader)}
              hasSubordinates={hasSubordinates(member.email)}
            />
          ))}
        </div>
      </div>
    ) : (
      <></>
    );

  return (
    <div className={classes.container} data-testid="TeamStructureContainer-container">
      {renderArrowUpButton()}
      {renderLeader()}
      {renderTeam()}
    </div>
  );
};

export default TeamStructureContainer;
