import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    wrapper: {
      textAlign: 'center',
    },
    text: {
      color: palette.secondary.main,
      marginTop: '11px',
    },
  }),
);
