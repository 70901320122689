/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */
/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler = (message, violations) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)
	console.warn(msg)
}
let analytics = () => {
	return window.analytics
}
/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}
/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message = {}) {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'javascript',
				version: '7.4.1',
			},
		},
	}
}
/**
 * @typedef AgentSegmentationAssigned
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 */
/**
 * @typedef BlockListDeleted
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListDownloaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef BlockListUploaded
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 */
/**
 * @typedef ProductsItem
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef CampaignCreated
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem1
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef CampaignDeleted
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem1[]} products - the array of all product objects in the list
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ProductsItem2
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef CampaignEdited
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem2[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef ProductsItem3
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef CampaignRegistrationFailed
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {ProductsItem3[]} products - the array of all product objects in the list
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 */
/**
 * @typedef CampaignStatusViewed
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 */
/**
 * @typedef CampaignUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef ProductsItem4
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef DuplicateLocalMissionSaved
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem4[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef ProductsItem5
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef DuplicateLocalMissionStarted
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem5[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef MissionsItem
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem6
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef EditLocalMissionStarted
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem[]} missions - The array of missions objects
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem6[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef ProductsItem7
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef EditSponsoredMissionStarted
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem7[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ErrorMessageViewed
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef FlexibleAudienceCompleted
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} flexible_condition - Type of the conditions (Period without using credit, Available credit, Acceptance of credit terms of use, Digitalization level, POC ID)
 * @property {string | null} flexible_credit_accepted - if the POC filter will consider credit accepted or not accepted
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {any[] | null} flexible_number - Value of the condition (when condition qual to Period without using credit or Available credit
 * @property {string | null} flexible_operator - Type of the operator (Equals to, Greater than, Greater than or equal to, Less than,Less than or equal to,Between)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 */
/**
 * @typedef FlexibleMainDetailsCompleted
 * @property {any[] | null} call_every - days for contact  the POC again
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 */
/**
 * @typedef ProductsItem8
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef FlexibleMissionCreated
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} customized_period - value of the days
 * @property {string | null} effectiveness_credit -
type of the effectiveness criteria (credit as payment or credit onborarding)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {any[] | null} minimum_amount_paid - value insert when effectiveness criteria is Credit as payment
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 * @property {ProductsItem8[]} products - the array of all product objects in the list
 * @property {boolean | null} remainig_days - include remaining days (T)
 * @property {string | null} time_limit - period to achieve the effectivenes (Within the mission period, Same day of the call, The day after the call, Customized period)
 */
/**
 * @typedef FlexibleMissionStatusChanged
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {string | null} flexible_mission_id - id/name of the flexible mission
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef GroupMissionsItem
 * @property {string | null} key - The mission name (REWARDS_CHALLENGES,1e7adc9c-2342-411f-ac57-bf7c09c5732a)'
 */
/**
 * @typedef TargetKpiItem
 * @property {string | null} daily - The value set as the daily target (00:05:00)
 * @property {boolean | null} invisible - The definition if the KPI will be displayed to the agent (T)
 * @property {string | null} monthly - The value set as the monthly target (00:05:00)
 * @property {string | null} type - The type of the KPI (AHT)
 */
/**
 * @typedef SegmentationsItem
 * @property {string | null} key - The name that will filter de POCs for the group (segment)
 * @property {any[] | null} values - The array of all value that will filter the POCs for this segmentation key (large-pocs)
 */
/**
 * @typedef VendorsItem
 * @property {string | null} id - The vendorId that the group will work for (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {SegmentationsItem[] | null} segmentations - The array of segmentations. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 */
/**
 * @typedef GroupChangesSaved
 * @property {string | null} group_all_local_missions - The location of the screen (Teams page, Group form)
 * @property {string | null} group_id - The id of the group, if applicable (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {GroupMissionsItem[] | null} group_missions - The array of group missions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} group_name - The title of the group, if applicable (Marketplace group)
 * @property {TargetKpiItem[] | null} targetKPI - The array of target kpis. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {any[] | null} users - The array of all agents of the group
 * @property {VendorsItem[] | null} vendors - The array of vendors. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 */
/**
 * @typedef GroupMissionsItem1
 * @property {string | null} key - The mission name (REWARDS_CHALLENGES,1e7adc9c-2342-411f-ac57-bf7c09c5732a)'
 */
/**
 * @typedef TargetKpiItem1
 * @property {string | null} daily - The value set as the daily target (00:05:00)
 * @property {boolean | null} invisible - The definition if the KPI will be displayed to the agent (T)
 * @property {string | null} monthly - The value set as the monthly target (00:05:00)
 * @property {string | null} type - The type of the KPI (AHT)
 */
/**
 * @typedef SegmentationsItem1
 * @property {string | null} key - The name that will filter de POCs for the group (segment)
 * @property {any[] | null} values - The array of all value that will filter the POCs for this segmentation key (large-pocs)
 */
/**
 * @typedef VendorsItem1
 * @property {string | null} id - The vendorId that the group will work for (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {SegmentationsItem1[] | null} segmentations - The array of segmentations. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 */
/**
 * @typedef GroupCreated
 * @property {string | null} group_all_local_missions - The location of the screen (Teams page, Group form)
 * @property {string | null} group_id - The id of the group, if applicable (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {GroupMissionsItem1[] | null} group_missions - The array of group missions. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {string | null} group_name - The title of the group, if applicable (Marketplace group)
 * @property {TargetKpiItem1[] | null} targetKPI - The array of target kpis. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 * @property {any[] | null} users - The array of all agents of the group
 * @property {VendorsItem1[] | null} vendors - The array of vendors. Attention ! Array props should directly contain the sub objects, not the ''items'' as an object like in the Protocols UI
 */
/**
 * @typedef GroupDeleted
 * @property {string | null} group_id - The id of the group, if applicable (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {string | null} group_name - The title of the group, if applicable (Marketplace group)
 */
/**
 * @typedef LanguageChanged
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 */
/**
 * @typedef LocalMissionAudienceCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef LocalMissionContentCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef MissionsItem1
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem9
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalMissionCreated
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem1[]} missions - The array of missions objects
 * @property {ProductsItem9[]} products - the array of all product objects in the list
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionDetailsPageViewed
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionMainCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef LocalMissionReportExported
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef MissionsItem2
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef ProductsItem10
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalMissionsChangesSaved
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem2[]} missions - The array of missions objects
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem10[]} products - the array of all product objects in the list
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef PeriodItem
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef LocalMissionsFilterClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {PeriodItem[] | null} period - Period
 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} type - all types selected (Coverage, Simple)
 */
/**
 * @typedef LocalMissionsNoResultsFound
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsPrioritySaved
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef LocalMissionsSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsStatusChanged
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalMissionsTabsInteraction
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef LocalProductsAdded
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 */
/**
 * @typedef ProductsItem11
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef LocalProductsLoaded
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {ProductsItem11[]} products - the array of all product objects in the list
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 */
/**
 * @typedef LocalProductsQuantityEdited
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 */
/**
 * @typedef MissionsPrioritization
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} mission_id -
id of the  mission
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef MissionsItem3
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef MissionsSaved
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {MissionsItem3[]} missions - The array of missions objects
 */
/**
 * @typedef MissionsTabInteraction
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 */
/**
 * @typedef MissionsItem4
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem1
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem12
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef MissionsUxButtonClicked
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {any[] | null} call_every - days for contact  the POC again
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {any[] | null} customized_period - value of the days
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_credit -
type of the effectiveness criteria (credit as payment or credit onborarding)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} flexible_condition - Type of the conditions (Period without using credit, Available credit, Acceptance of credit terms of use, Digitalization level, POC ID)
 * @property {string | null} flexible_credit_accepted - if the POC filter will consider credit accepted or not accepted
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {string | null} flexible_mission_id - id/name of the flexible mission
 * @property {any[] | null} flexible_number - Value of the condition (when condition qual to Period without using credit or Available credit
 * @property {string | null} flexible_operator - Type of the operator (Equals to, Greater than, Greater than or equal to, Less than,Less than or equal to,Between)
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {any[] | null} minimum_amount_paid - value insert when effectiveness criteria is Credit as payment
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_id -
id of the  mission
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem4[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem1[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem12[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {boolean | null} remainig_days - include remaining days (T)
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {string | null} time_limit - period to achieve the effectivenes (Within the mission period, Same day of the call, The day after the call, Customized period)
 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef NewFlexibleMission
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef NewLocalMissionStarted
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 */
/**
 * @typedef PageViewed
 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef RetryButtonClicked
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef ServiceAdded
 * @property {string | null} service_name - title of the service (Bill payment)
 */
/**
 * @typedef ServiceSaved
 * @property {string | null} country - country being configured
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} service_name - title of the service (Bill payment)
 */
/**
 * @typedef ServiceStatusChanged
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {string | null} service_name - title of the service (Bill payment)
 */
/**
 * @typedef ServicesChangesSaved
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 */
/**
 * @typedef VariablePricingItem1
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem1
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem1[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef ServicesMissionSaved
 * @property {string | null} country - country being configured
 * @property {ServicesItem1[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 */
/**
 * @typedef ServicesUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef SidebarInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 */
/**
 * @typedef SponsoredAgentSetupCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef SponsoredContentCompleted
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef MissionsItem5
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem2
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem13
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem2
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem2
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem2[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef SponsoredMainDetailsCompleted
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {any[] | null} call_every - days for contact  the POC again
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {any[] | null} customized_period - value of the days
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_credit -
type of the effectiveness criteria (credit as payment or credit onborarding)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} flexible_condition - Type of the conditions (Period without using credit, Available credit, Acceptance of credit terms of use, Digitalization level, POC ID)
 * @property {string | null} flexible_credit_accepted - if the POC filter will consider credit accepted or not accepted
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {string | null} flexible_mission_id - id/name of the flexible mission
 * @property {any[] | null} flexible_number - Value of the condition (when condition qual to Period without using credit or Available credit
 * @property {string | null} flexible_operator - Type of the operator (Equals to, Greater than, Greater than or equal to, Less than,Less than or equal to,Between)
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {any[] | null} minimum_amount_paid - value insert when effectiveness criteria is Credit as payment
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_id -
id of the  mission
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem5[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem2[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem13[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {boolean | null} remainig_days - include remaining days (T)
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem2[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {string | null} time_limit - period to achieve the effectivenes (Within the mission period, Same day of the call, The day after the call, Customized period)
 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef ProductsItem14
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef SponsoredMissionChangesSaved
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem14[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef ProductsItem15
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef SponsoredMissionCreated
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {ProductsItem15[]} products - the array of all product objects in the list
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 */
/**
 * @typedef SponsoredMissionStatusChanged
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 */
/**
 * @typedef SponsoredSearchInteraction
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 */
/**
 * @typedef SponsoredUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * @typedef MissionsItem6
 * @property {boolean | null} is_active - If the mission is set as active. It's a boolean
 * @property {number | null} order - Order of the mission (only if it's a primary, otherwise must be null). Sample: 2
 * @property {string | null} type - Type of the mission. Samples: Gap, Rewards
 */
/**
 * @typedef PeriodItem3
 * @property {string | null} End - end date (timestamp format) (2021-07-05T00:00:00.000Z)
 * @property {string | null} Start - start date (timestamp format) (2021-07-05T00:00:00.000Z)
 */
/**
 * @typedef ProductsItem16
 * @property {string | null} combo_id - combo ID. Sample: DT01290007980
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T), if applicable.
 * @property {string | null} sku - product SKU. Sample: ABIC001431
 * @property {number | null} suggested_quantity - suggested quantity of the item. Sample: 4
 */
/**
 * @typedef VariablePricingItem3
 * @property {string} attribute_value - value of the attribute (Grocery stores AB01)
 * @property {string | null} poc_attribute - attribute of the POC (Segment)
 * @property {number | null} value - cost value (500)
 */
/**
 * @typedef ServicesItem3
 * @property {string | null} description - description of the service (Will enable paying bills with rewards points.)
 * @property {boolean | null} is_available - if the service is available (True if available False if not)
 * @property {string | null} name - title of the service (Bill payment)
 * @property {number | null} preset_value - value in reward points when pricing method is preset (800)
 * @property {string | null} pricing_method - pricing method (Preset, Variable)
 * @property {VariablePricingItem3[]} variable_pricing - array of the variable pricing objects
 */
/**
 * @typedef TeamStructureUxButtonClicked
 * @property {string | null} DDC - Direct distribution center. Sample: 0575
 * @property {string | null} agent_id - Unique user identification (email)
 * @property {any[] | null} agent_segmentation - Segmentation of the agent. Sample: C2B
 * @property {string | null} block_list - Name of the block list. Sample: block_list_january
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} button_selection - Selected option. Samples: English, spanish
 * @property {any[] | null} call_every - days for contact  the POC again
 * @property {string | null} call_id - Id of the call (12123)
 * @property {string | null} campaign_description - Description of the campaign. Sample: campaign to offer our Special Colorado Beers
 * @property {any[] | null} campaign_error_type - The error type(s) that occured in the campaign registration. Sample: invalid CSV format
 * @property {string | null} campaign_name - Name of the campaign. Sample: Summer Favourites

 * @property {number | null} campaign_priority - The prioritization rank of the campaign. 1 if primary and 2 if secondary
 * @property {string | null} campaign_script - Script of the campaign. Sample: 1. introduce campaign; 2. ask about customer mood
 * @property {string | null} campaign_segmentation - Segmentation of the campaign. Sample: C2B
 * @property {string | null} campaign_type - Type of the campaign. Samples: Focus SKU, B2O
 * @property {string | null} category - All categories selected (Digital education, Value Creation)
 * @property {string | null} combo_id - combo ID (DT01290007980)
 * @property {boolean | null} [consider_block_list] - if the POC filter will consider the block list (T)
 * @property {boolean | null} consider_delivery_window - if the POC filter will consider the delivery window (T)
 * @property {string | null} country - country being configured
 * @property {any[] | null} customized_period - value of the days
 * @property {string | null} description - description of the item. Sample: Will enable paying bills with rewards points.
 * @property {any[] | null} digitalization_level - list of included digitalization levels (["Adoption", "Full digital"])
 * @property {string | null} effectiveness_credit -
type of the effectiveness criteria (credit as payment or credit onborarding)
 * @property {string | null} effectiveness_criteria - type of the effectiveness criteria (at least one)
 * @property {string | null} end_date - End date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} error_message - Text displayed in the UI that describes the error. Sample: Field required
 * @property {string | null} error_type - Error that occured. Sample: no_connection

 * @property {string | null} filter_option - Filter selection. Samples: gap only, premium, core. Null if not applicable
 * @property {string | null} flexible_condition - Type of the conditions (Period without using credit, Available credit, Acceptance of credit terms of use, Digitalization level, POC ID)
 * @property {string | null} flexible_credit_accepted - if the POC filter will consider credit accepted or not accepted
 * @property {string | null} flexible_mission - title of the sponsored mission (New Michelob Ultra Longneck)
 * @property {string | null} flexible_mission_id - id/name of the flexible mission
 * @property {any[] | null} flexible_number - Value of the condition (when condition qual to Period without using credit or Available credit
 * @property {string | null} flexible_operator - Type of the operator (Equals to, Greater than, Greater than or equal to, Less than,Less than or equal to,Between)
 * @property {string | null} hierarchy - Hierarchy level. Samples: Supervisor, Agent
 * @property {boolean | null} is_active - if the service is active or not (T)
 * @property {boolean | null} is_out_of_stock - if the product is out of stock (T)
 * @property {boolean | null} is_promotion - if the product is a promotion/deal (T)
 * @property {string | null} language_selected - UI language selected. Samples: EN, ES
 * @property {string | null} local_mission - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_mission_id - Id of the local mission
 * @property {string | null} local_mission_title - Title of the local mission (New Michelob Ultra Longneck)
 * @property {string | null} local_segmentation - The name of the segmentation of the local mission
 * @property {any[] | null} minimum_amount_paid - value insert when effectiveness criteria is Credit as payment
 * @property {string | null} minimum_quantity - number of the minimum quantity suggested for that product/combo or mission.
 * @property {string | null} mission - Title of the sponsored/local mission (New Michelob Ultra Longneck)
 * @property {string | null} mission_categories -
categories of the flexible mission (Fintech, Marketplace, Rewards, Services)
 * @property {string | null} mission_category - category of the local mission (digital education)
 * @property {string | null} mission_id -
id of the  mission
 * @property {string | null} mission_priority - Prioritization of the mission. Samples: Primary, Secondary
 * @property {string | null} mission_priority_1 - The first mission priority of the agent. Sample: GAP
 * @property {string | null} mission_priority_2 - The second mission priority of the agent. Sample: MARKETPLACE_ACTIVATION
 * @property {string | null} mission_priority_3 - The third mission priority of the agent. Sample: REWARDS_CHALLENGES
 * @property {string | null} mission_priority_4 - The fourth mission priority of the agent. Sample: SPONSORED
 * @property {string | null} mission_priority_5 - The fifth mission priority of the agent. Sample: SERVICES
 * @property {any[] | null} mission_tags - The list of included tags
 * @property {string | null} mission_type - Triggered when sending information about the selected mission type in a new sponsored mission.
 * @property {MissionsItem6[]} missions - The array of missions objects
 * @property {string | null} name - name of Product (Brahma Beer Pilsen 10.5 ONZ - Pack - 12 Unit)
 * @property {number | null} new_mission_position - new order of the mission moved
 * @property {string | null} partner - partner of the mission (Diageo)

 * @property {Record<string, any>} [path] - Path of the screen. Samples: /call
 * @property {PeriodItem3[] | null} period - Period
 * @property {string | null} poc_id - Id of the POC (446109)
 * @property {number | null} preset_value - value in rewards points when pricing method is preset. Sample: 800
 * @property {number | null} previous_mission_position - previous order of the mission moved
 * @property {string | null} pricing_method - pricing method. Samples: Preset, Variable
 * @property {string | null} priority_change_method - method the priority change was saved (drag and drop; position edition)
 * @property {ProductsItem16[]} products - the array of all product objects in the list
 * @property {number | null} quantity - Quantity of invalid POCs listed in a single campaign
 * @property {Record<string, any>} [referrer] - URL the user came from to get to the current page. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/login
 * @property {boolean | null} remainig_days - include remaining days (T)
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 * @property {string | null} script - script of the mission. Sample: 1. Introduce the reason; 2. Ask how the customer is...
 * @property {Record<string, any>} [search] - The term used in search modules. Samples: Invoices
 * @property {string | null} search_query - Text typed in the product search field. Sample: Brahma Pilsen
 * @property {string | null} segment - Segment of the campaign. Sample: 04
 * @property {string | null} service_name - title of the service (Bill payment)
 * @property {ServicesItem3[] | null} services - array of services objects
 * @property {string | null} services_script - script of the mission (1. Introduce call reason; 2. Ask how the customer is;...)
 * @property {string | null} sku - product SKU (ABIC001431)
 * @property {boolean | null} sponsor - sponsor of the mission (Diageo)
 * @property {string | null} sponsor_partner - The name of the sponsor of this mission
 * @property {string | null} sponsored_mission - Title of the sponsored mission. Sample: New Michelob Ultra Longneck

 * @property {boolean | null} sponsored_only - If the filter sponsored only was selected: (true, false)
 * @property {string | null} [sponsored_segmentation] - the name of the segmentation of the sponsored mission
 * @property {boolean | null} sponsored_tag - If this local mission is a sponsored (T)
 * @property {string | null} start_date - Start date (timestamp format). Sample: 2021-07-05T00:00:00.000Z
 * @property {string | null} status - all statuses selected (Scheduled, Ongoing, Concluded, Expired, Paused)
 * @property {string | null} tab_label - Label of the tab clicked. Samples: Primary Missions, Secondary Missions
 * @property {string | null} tag_name - Tag name of the campaign. Sample: #Summer2021

 * @property {string | null} time_limit - period to achieve the effectivenes (Within the mission period, Same day of the call, The day after the call, Customized period)
 * @property {number | null} time_of_day - The hour of day the event occured (drill down to hour only). Samples: 15, 09
 * @property {Record<string, any>} [title] - Title of the page displayed to the use. Sample: Gap & Status
 * @property {string | null} type - all types selected (Coverage, Simple)
 * @property {Record<string, any>} [url] - URL of the user is in. Samples: https://admin-portal-qa.eastus2.cloudapp.azure.com/#/tlp/call
 */
/**
 * @typedef TeamsUxButtonClicked
 * @property {string | null} button_label - Name of the button as is on the code. Sample: update-agents-kpis-card
 * @property {string | null} button_name - Text of the button in the local language as is in the UI. Samples: Recarregar, Gap Only
 * @property {string | null} group_id - The id of the group, if applicable (52b2e1c5-578a-445e-85c5-d190cba8df2d)
 * @property {string | null} group_name - The title of the group, if applicable (Marketplace group)
 * @property {string | null} screen_name - Name os the screen user is in. Samples: Agent's KPIs, GAP & Status
 * @property {string | null} screen_section - Location of the screen. Samples: suggested order, last orders
 */
/**
 * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
 *
 * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function agentSegmentationAssigned(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Agent Segmentation Assigned',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on confirm button inside block list deletion modal and the block list is deleted
 *
 * @param {BlockListDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDeleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Block List Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the block list download button and the list is downloaded
 *
 * @param {BlockListDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListDownloaded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Block List Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on confirm button inside confirmation modal and a new block list is submitted
 *
 * @param {BlockListUploaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function blockListUploaded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Block List Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the "create" button in the creation modal and the campaign is created with success
 *
 * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
 *
 * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignDeleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
 *
 * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignEdited(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User tries to create/edit a campaign, but the action returns one or more errors
 *
 * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignRegistrationFailed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Registration Failed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the search field/button in the Focus SKU page
 *
 * @param {CampaignSearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignSearchInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Search Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
 *
 * @param {CampaignStatusViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignStatusViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign Status Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * > User clicks on ""Create new"" to create a new campaign |
> User clicks on ""expand"" or ""collapse"" campaign details
 *
 * @param {CampaignUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function campaignUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Campaign UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the Save button.
 *
 * @param {DuplicateLocalMissionSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function duplicateLocalMissionSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Duplicate Local Mission Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the Duplicate button.
 *
 * @param {DuplicateLocalMissionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function duplicateLocalMissionStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Duplicate Local Mission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User click on th 'edit' button local of Mission Started
 *
 * @param {EditLocalMissionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editLocalMissionStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Local Mission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the "Edit" button of a Sponsored Mission and goes to the edition page
 *
 * @param {EditSponsoredMissionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editSponsoredMissionStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Edit Sponsored Mission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * An error is displayed to the user or an error screen is loaded
 *
 * @param {ErrorMessageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorMessageViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Error Message Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 2 of the flexible Missions and advances to step 3
 *
 * @param {FlexibleAudienceCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flexibleAudienceCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Flexible Audience Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 1 of the flexible missions and advances to step 2
 *
 * @param {FlexibleMainDetailsCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flexibleMainDetailsCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Flexible Main Details Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes the flexible creation process and a new mission is created successfully
 *
 * @param {FlexibleMissionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flexibleMissionCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Flexible Mission Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on the toggle inside the cards and the mission is activated/deactivated
 *
 * @param {FlexibleMissionStatusChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function flexibleMissionStatusChanged(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Flexible Mission Status Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User saves changes after editing a Group
 *
 * @param {GroupChangesSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function groupChangesSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Group Changes Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completes the Group creation process and a new Group is created successfully
 *
 * @param {GroupCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function groupCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Group Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User confirms 'Delete' inside the delete modal and the group is deleted successfully
 *
 * @param {GroupDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function groupDeleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Group Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User selects a language through header dropdown menu
 *
 * @param {LanguageChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function languageChanged(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Language Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 3 of the Local Missions and advances to step 4
 *
 * @param {LocalMissionAudienceCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionAudienceCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Audience Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 2 of the Local Missions and advances to step 3
 *
 * @param {LocalMissionContentCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionContentCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Content Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks in the “finish” button in the step 4 of the creation process, indicating that a new mission is created and saved.
 *
 * @param {LocalMissionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks in the local mission title/arrow to access the Details page.
 *
 * @param {LocalMissionDetailsPageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionDetailsPageViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Details Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 1 of the Local Missions and advances to step 2
 *
 * @param {LocalMissionMainCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionMainCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Main Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks to export the report in the Local mission Details Page
 *
 * @param {LocalMissionReportExported} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionReportExported(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission Report Exported',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user performs an UX action in the local missions creation, edit, duplicate and details pages
 *
 * @param {LocalMissionUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Mission UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on 'save' after change mission.
 *
 * @param {LocalMissionsChangesSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsChangesSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Changes Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the Local mission’s filter component
 *
 * @param {LocalMissionsFilterClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsFilterClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Filter Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Search and/or Filters used showed “No results” in the Local missions page
 *
 * @param {LocalMissionsNoResultsFound} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsNoResultsFound(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions No Results Found',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User changes the priority of the sponsored/local missions through the drag and drop or position number edition, inside “Active” sub-page of the Sponsored missions/Local missions page
 *
 * @param {LocalMissionsPrioritySaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsPrioritySaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Priority Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User search a value in the search bar of local missions page
 *
 * @param {LocalMissionsSearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsSearchInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Search Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on the toggle inside the cards and the mission is activated/deactivated, either in the “Active” or “All missions” sub-page.
 *
 * @param {LocalMissionsStatusChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsStatusChanged(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Status Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User toggles between Active and All missions inside Sponsored missions/Local missions page.
 *
 * @param {LocalMissionsTabsInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localMissionsTabsInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Missions Tabs Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart.
 *
 * @param {LocalProductsAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsAdded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Products Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * The products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default
 *
 * @param {LocalProductsLoaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsLoaded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Products Loaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user alters the quantity of a product in the Local mission tab by using the arrows or typing the quantity.
 *
 * @param {LocalProductsQuantityEdited} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localProductsQuantityEdited(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Local Products Quantity Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "save changes" inside the missions management page and the changes are saved
 *
 * @param {MissionsPrioritization} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsPrioritization(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Missions Prioritization',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "apply changes" inside the missions management page and the changes are saved
 *
 * @param {MissionsSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Missions Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User toggles between Primary Missions and Secondary Missions inside missions page
 *
 * @param {MissionsTabInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsTabInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Missions Tab Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "edit" inside any mission card in Missions Management
 *
 * @param {MissionsUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function missionsUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Missions UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "Create a mission" inside Missions management page
 *
 * @param {NewFlexibleMission} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newFlexibleMission(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'New Flexible Mission',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "Create Mission" inside Local Missions page and goes to step 1
 *
 * @param {NewLocalMissionStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newLocalMissionStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'New Local Mission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "New sponsored mission" inside Sponsored Missions page and goes to step 1
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newSponsoredMissionStarted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'New Sponsored Mission Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * When the user accesses another page in the application
 *
 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
 *
 * @param {RetryButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function retryButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Retry Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on "Add service" inside service creation modal and a new service is added
 *
 * @param {ServiceAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceAdded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Service Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on "Add service" or "Save changes" inside Services page and the mission is saved
 *
 * @param {ServiceSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Service Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user switches any toggle inside service's listing and the availability is changed
 *
 * @param {ServiceStatusChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function serviceStatusChanged(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Service Status Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user saves changes after editing the services' script and parameters
 *
 * @param {ServicesChangesSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesChangesSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Services Changes Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on "Save changes" inside Services page and the mission is saved
 *
 * @param {ServicesMissionSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesMissionSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Services Mission Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user performs an UX action in the Services page
 *
 * @param {ServicesUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function servicesUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Services UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on any button inside sidebar section
 *
 * @param {SidebarInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sidebarInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sidebar Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on "next" after setting the step 3 of the Sponsored Missions and advances to step 4
 *
 * @param {SponsoredAgentSetupCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredAgentSetupCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Agent Setup Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 2 of the Sponsored Missions and advances to step 3
 *
 * @param {SponsoredContentCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredContentCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Content Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "next" after setting the step 1 of the Sponsored Missions and advances to step 2
 *
 * @param {SponsoredMainDetailsCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMainDetailsCompleted(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Main Details Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user saves changes after editing a sponsored mission
 *
 * @param {SponsoredMissionChangesSaved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionChangesSaved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Mission Changes Saved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user completes the sponsored creation process and a new mission is created successfully
 *
 * @param {SponsoredMissionCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionCreated(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Mission Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on the toggle inside the cards and the mission is activated/deactivated
 *
 * @param {SponsoredMissionStatusChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredMissionStatusChanged(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Mission Status Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * a file is uploaded with success after clicking on "Upload a CSV file" inside creation step 4
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredPocListUploaded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored POC List Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user alters a sponsored mission's product quantity
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductQuantityEdited(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Product Quantity Edited',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user removes a product from the sponsored mission
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredProductRemoved(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User interacts with the search field/button in the Content step
 *
 * @param {SponsoredSearchInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredSearchInteraction(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored Search Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user performs an UX action in the sponsored missions page
 *
 * @param {SponsoredUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Sponsored UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicks on "Import" after a Team Structure file is uploaded
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureImported(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Team Structure Imported',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * user clicks on UX buttons across the Team Structure page
 *
 * @param {TeamStructureUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Team Structure UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * A file is uploaded with success after user clicks on "Upload a CSV file" inside Team Structure page
 *
 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamStructureUploaded(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Team Structure Uploaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performs an UX action in the Teams page for group creation, group visualization, group edition or deletion
 *
 * @param {TeamsUxButtonClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function teamsUxButtonClicked(props, options, callback) {
	const a = analytics()
	if (a) {
		a.track(
			'Teams UX Button Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * User clicks on "confirm" in the agent setup modal and assigns a segmentation with success
	 *
	 * @param {AgentSegmentationAssigned} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	agentSegmentationAssigned,
	/**
	 * User clicks on confirm button inside block list deletion modal and the block list is deleted
	 *
	 * @param {BlockListDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	blockListDeleted,
	/**
	 * User clicks on the block list download button and the list is downloaded
	 *
	 * @param {BlockListDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	blockListDownloaded,
	/**
	 * User clicks on confirm button inside confirmation modal and a new block list is submitted
	 *
	 * @param {BlockListUploaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	blockListUploaded,
	/**
	 * User clicks on the "create" button in the creation modal and the campaign is created with success
	 *
	 * @param {CampaignCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignCreated,
	/**
	 * User clicks on the "delete" button in the confirmation modal and the campaign is deleted with success
	 *
	 * @param {CampaignDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignDeleted,
	/**
	 * User clicks on the "confirm" button in the edit modal and the campaign is edited with success
	 *
	 * @param {CampaignEdited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignEdited,
	/**
	 * User tries to create/edit a campaign, but the action returns one or more errors
	 *
	 * @param {CampaignRegistrationFailed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignRegistrationFailed,
	/**
	 * User interacts with the search field/button in the Focus SKU page
	 *
	 * @param {CampaignSearchInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignSearchInteraction,
	/**
	 * User clicks on the warning icon in the campaigns listing and a list of invalid POCs is loaded
	 *
	 * @param {CampaignStatusViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	campaignStatusViewed,
	/**
     * > User clicks on ""Create new"" to create a new campaign |
    > User clicks on ""expand"" or ""collapse"" campaign details
     *
     * @param {CampaignUxButtonClicked} props - The analytics properties that will be sent to Segment.
     * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
     * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
     * 		call is fired.
     */
	campaignUxButtonClicked,
	/**
	 * User clicks on the Save button.
	 *
	 * @param {DuplicateLocalMissionSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	duplicateLocalMissionSaved,
	/**
	 * User clicks on the Duplicate button.
	 *
	 * @param {DuplicateLocalMissionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	duplicateLocalMissionStarted,
	/**
	 * User click on th 'edit' button local of Mission Started
	 *
	 * @param {EditLocalMissionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editLocalMissionStarted,
	/**
	 * User clicks on the "Edit" button of a Sponsored Mission and goes to the edition page
	 *
	 * @param {EditSponsoredMissionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	editSponsoredMissionStarted,
	/**
	 * An error is displayed to the user or an error screen is loaded
	 *
	 * @param {ErrorMessageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	errorMessageViewed,
	/**
	 * User clicks on "next" after setting the step 2 of the flexible Missions and advances to step 3
	 *
	 * @param {FlexibleAudienceCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flexibleAudienceCompleted,
	/**
	 * User clicks on "next" after setting the step 1 of the flexible missions and advances to step 2
	 *
	 * @param {FlexibleMainDetailsCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flexibleMainDetailsCompleted,
	/**
	 * User completes the flexible creation process and a new mission is created successfully
	 *
	 * @param {FlexibleMissionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flexibleMissionCreated,
	/**
	 * user clicks on the toggle inside the cards and the mission is activated/deactivated
	 *
	 * @param {FlexibleMissionStatusChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	flexibleMissionStatusChanged,
	/**
	 * User saves changes after editing a Group
	 *
	 * @param {GroupChangesSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	groupChangesSaved,
	/**
	 * User completes the Group creation process and a new Group is created successfully
	 *
	 * @param {GroupCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	groupCreated,
	/**
	 * User confirms 'Delete' inside the delete modal and the group is deleted successfully
	 *
	 * @param {GroupDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	groupDeleted,
	/**
	 * User selects a language through header dropdown menu
	 *
	 * @param {LanguageChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	languageChanged,
	/**
	 * User clicks on "next" after setting the step 3 of the Local Missions and advances to step 4
	 *
	 * @param {LocalMissionAudienceCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionAudienceCompleted,
	/**
	 * User clicks on "next" after setting the step 2 of the Local Missions and advances to step 3
	 *
	 * @param {LocalMissionContentCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionContentCompleted,
	/**
	 * User clicks in the “finish” button in the step 4 of the creation process, indicating that a new mission is created and saved.
	 *
	 * @param {LocalMissionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionCreated,
	/**
	 * User clicks in the local mission title/arrow to access the Details page.
	 *
	 * @param {LocalMissionDetailsPageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionDetailsPageViewed,
	/**
	 * User clicks on "next" after setting the step 1 of the Local Missions and advances to step 2
	 *
	 * @param {LocalMissionMainCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionMainCompleted,
	/**
	 * User clicks to export the report in the Local mission Details Page
	 *
	 * @param {LocalMissionReportExported} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionReportExported,
	/**
	 * user performs an UX action in the local missions creation, edit, duplicate and details pages
	 *
	 * @param {LocalMissionUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionUxButtonClicked,
	/**
	 * User clicks on 'save' after change mission.
	 *
	 * @param {LocalMissionsChangesSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsChangesSaved,
	/**
	 * User interacts with the Local mission’s filter component
	 *
	 * @param {LocalMissionsFilterClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsFilterClicked,
	/**
	 * Search and/or Filters used showed “No results” in the Local missions page
	 *
	 * @param {LocalMissionsNoResultsFound} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsNoResultsFound,
	/**
	 * User changes the priority of the sponsored/local missions through the drag and drop or position number edition, inside “Active” sub-page of the Sponsored missions/Local missions page
	 *
	 * @param {LocalMissionsPrioritySaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsPrioritySaved,
	/**
	 * User search a value in the search bar of local missions page
	 *
	 * @param {LocalMissionsSearchInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsSearchInteraction,
	/**
	 * User clicks on the toggle inside the cards and the mission is activated/deactivated, either in the “Active” or “All missions” sub-page.
	 *
	 * @param {LocalMissionsStatusChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsStatusChanged,
	/**
	 * User toggles between Active and All missions inside Sponsored missions/Local missions page.
	 *
	 * @param {LocalMissionsTabsInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localMissionsTabsInteraction,
	/**
	 * User clicks on "Add" button in either promo and combo sections and the item is added to the order cart.
	 *
	 * @param {LocalProductsAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localProductsAdded,
	/**
	 * The products list for a single local mission is loaded in the screen after user expands its component (or just when it is loaded, in case of the first mission in the list, which comes expanded by default
	 *
	 * @param {LocalProductsLoaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localProductsLoaded,
	/**
	 * user alters the quantity of a product in the Local mission tab by using the arrows or typing the quantity.
	 *
	 * @param {LocalProductsQuantityEdited} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	localProductsQuantityEdited,
	/**
	 * User clicks on "save changes" inside the missions management page and the changes are saved
	 *
	 * @param {MissionsPrioritization} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missionsPrioritization,
	/**
	 * User clicks on "apply changes" inside the missions management page and the changes are saved
	 *
	 * @param {MissionsSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missionsSaved,
	/**
	 * User toggles between Primary Missions and Secondary Missions inside missions page
	 *
	 * @param {MissionsTabInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missionsTabInteraction,
	/**
	 * User clicks on "edit" inside any mission card in Missions Management
	 *
	 * @param {MissionsUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	missionsUxButtonClicked,
	/**
	 * User clicks on "Create a mission" inside Missions management page
	 *
	 * @param {NewFlexibleMission} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newFlexibleMission,
	/**
	 * User clicks on "Create Mission" inside Local Missions page and goes to step 1
	 *
	 * @param {NewLocalMissionStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newLocalMissionStarted,
	/**
	 * User clicks on "New sponsored mission" inside Sponsored Missions page and goes to step 1
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newSponsoredMissionStarted,
	/**
	 * When the user accesses another page in the application
	 *
	 * @param {PageViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User clicks on any retry button after an error occurs (e.g.: click on "reload list" after it fails to load)
	 *
	 * @param {RetryButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	retryButtonClicked,
	/**
	 * user clicks on "Add service" inside service creation modal and a new service is added
	 *
	 * @param {ServiceAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	serviceAdded,
	/**
	 * user clicks on "Add service" or "Save changes" inside Services page and the mission is saved
	 *
	 * @param {ServiceSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	serviceSaved,
	/**
	 * user switches any toggle inside service's listing and the availability is changed
	 *
	 * @param {ServiceStatusChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	serviceStatusChanged,
	/**
	 * user saves changes after editing the services' script and parameters
	 *
	 * @param {ServicesChangesSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	servicesChangesSaved,
	/**
	 * user clicks on "Save changes" inside Services page and the mission is saved
	 *
	 * @param {ServicesMissionSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	servicesMissionSaved,
	/**
	 * user performs an UX action in the Services page
	 *
	 * @param {ServicesUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	servicesUxButtonClicked,
	/**
	 * User clicks on any button inside sidebar section
	 *
	 * @param {SidebarInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sidebarInteraction,
	/**
	 * user clicks on "next" after setting the step 3 of the Sponsored Missions and advances to step 4
	 *
	 * @param {SponsoredAgentSetupCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredAgentSetupCompleted,
	/**
	 * User clicks on "next" after setting the step 2 of the Sponsored Missions and advances to step 3
	 *
	 * @param {SponsoredContentCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredContentCompleted,
	/**
	 * User clicks on "next" after setting the step 1 of the Sponsored Missions and advances to step 2
	 *
	 * @param {SponsoredMainDetailsCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredMainDetailsCompleted,
	/**
	 * user saves changes after editing a sponsored mission
	 *
	 * @param {SponsoredMissionChangesSaved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredMissionChangesSaved,
	/**
	 * user completes the sponsored creation process and a new mission is created successfully
	 *
	 * @param {SponsoredMissionCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredMissionCreated,
	/**
	 * user clicks on the toggle inside the cards and the mission is activated/deactivated
	 *
	 * @param {SponsoredMissionStatusChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredMissionStatusChanged,
	/**
	 * a file is uploaded with success after clicking on "Upload a CSV file" inside creation step 4
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredPocListUploaded,
	/**
	 * user alters a sponsored mission's product quantity
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredProductQuantityEdited,
	/**
	 * user removes a product from the sponsored mission
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredProductRemoved,
	/**
	 * User interacts with the search field/button in the Content step
	 *
	 * @param {SponsoredSearchInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredSearchInteraction,
	/**
	 * user performs an UX action in the sponsored missions page
	 *
	 * @param {SponsoredUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	sponsoredUxButtonClicked,
	/**
	 * User clicks on "Import" after a Team Structure file is uploaded
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamStructureImported,
	/**
	 * user clicks on UX buttons across the Team Structure page
	 *
	 * @param {TeamStructureUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamStructureUxButtonClicked,
	/**
	 * A file is uploaded with success after user clicks on "Upload a CSV file" inside Team Structure page
	 *
	 * @param {Record<string, any>} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamStructureUploaded,
	/**
	 * User performs an UX action in the Teams page for group creation, group visualization, group edition or deletion
	 *
	 * @param {TeamsUxButtonClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	teamsUxButtonClicked,
}
export default new Proxy(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method]
		}
		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_1sLaHQgU12daYzLv3kSiK4HPkrn`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
