import React from 'react';

function SuccessIconToastify(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M12 3a9 9 0 1 1 0 18 9 9 0 0 1 0-18zm0 1.636a7.364 7.364 0 1 0 0 14.728 7.364 7.364 0 0 0 0-14.728zm0 6.546c.42 0 .765.316.813.723l.005.095v3.273a.818.818 0 0 1-1.63.095l-.006-.095V12c0-.452.366-.818.818-.818zm.008-3.273a.818.818 0 0 1 .096 1.631L12 9.545a.818.818 0 0 1-.095-1.63l.103-.006z"
          id="mqnd0dq3xa"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="qkjs2n6u0b" fill="#fff">
          <use xlinkHref="#mqnd0dq3xa" />
        </mask>
        <use fill="#007A78" fillRule="nonzero" xlinkHref="#mqnd0dq3xa" />
        <g mask="url(#qkjs2n6u0b)" fill="#007A78">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
}

export default SuccessIconToastify;
