import React, { ReactText } from 'react';
import { toast } from 'react-toastify';
import ErrorIconToastify from '../../assets/icons/ErrorIconToast';
import SuccessIconToastify from '../../assets/icons/SuccessIconToast';
import WarningIconToastify from '../../assets/icons/WarningIconToast';

interface CustomToastProps {
  message: string;
  type?: 'success' | 'error' | 'warning' | 'default';
}

const CustomToast = ({ type = 'default', message }: CustomToastProps): ReactText => {
  if (type && type === 'success') {
    return toast(message, {
      icon: <SuccessIconToastify />,
      style: {
        backgroundColor: '#d1f7f6',
      },
      toastId: 'successToast',
    });
  }

  if (type && type === 'error') {
    return toast(message, {
      icon: <ErrorIconToastify />,
      style: {
        backgroundColor: '#fbe9e9',
        minHeight: '48px',
      },
      toastId: 'errorToast',
    });
  }

  if (type && type === 'warning') {
    return toast(message, {
      icon: <WarningIconToastify />,
      style: {
        backgroundColor: '#ffebd0',
      },
      toastId: 'warningToast',
    });
  }

  return toast(message);
};

export default CustomToast;
