import MessageMap from '../i18n.d';

const esDO: MessageMap = {
  ImportDialog: {
    IMPORT_BTN_TEXT: 'Importar',
    IMPORT_STRUCTURE: 'Importar estructura',
    WARNING_OVERRIDE:
      'Podrás cargar un archivo con la estructura completa. Con esta acción se reemplazan las estructuras actuales.',
    CURRENT_STRUCTURE: 'Estructura actual',
    SAVE_BACKUP: 'Puedes guardar un backup con la estructura actual del equipo. ',
    DOWNLOAD_BACKUP: 'Descargar el archivo de backup',
    UPLOAD_FROM_FILE: 'Cargar desde el archivo',
    ConfirmationModal: {
      MODAL_OVERRIDE: '¿Estás seguro que deseas reemplazar la estructura actual?',
      MODAL_WARNING_A: 'La estructura actual será reemplazada por la nueva.',
      MODAL_WARNING_B: 'Esta acción no puede ser dehecha.',
      CANCEL_BTN_TEXT: 'Cancelar',
      CONFIRM_OVERRIDE: 'Reemplazar',
    },
  },
  TeamStructureTitle: {
    TEAM_STRUCTURE_TITLE: 'Estructura del equipo',
  },
  NoStructureCardReadOnly: {
    NO_STRUCTURE_CREATED: 'No hay estructura creada aún',
  },
  NoStructureCard: {
    NO_STRUCTURE_CREATED: 'No hay estructura creada aún',
    CAN_UPLOAD_FILE: 'Podrás cargar un archivo con la estructura.',
    UPLOAD_FROM_TEMPLATE: 'Cargar un archivo CSV desde nuestra plantilla.',
    DOWNLOAD_TEMPLATE: 'Descargar plantilla',
  },
  TemplateDownload: {
    UPLOAD_FROM_TEMPLATE: 'Cargar un archivo CSV desde nuestra plantilla.',
    DOWNLOAD_TEMPLATE: 'Descargar plantilla',
  },
  ImportConfirmationCard: {
    CONFIRMATION_WARNING: '¿Estás seguro que quieres importar la estructura del equipo?',
    SUCCESS_WARNING: 'Archivo procesado con éxito, está listo para ser importado. ',
    CANCEL_BTN_TEXT: 'Cancelar',
    IMPORT_BTN_TEXT: 'Importar',
  },
  DragAndDropInputFile: {
    DROP_FILE_TEXT: 'Soltar archivo para cargarlo',
    UPLOAD_CSV_TEXT: 'Cargar un archivo CSV',
    DRAG_AND_DROP_TEXT: 'o arrastrar un y soltarlo aquí',
  },
  ImportedFile: {
    POSITION_FOUND_TEXT: 'posición encontrada',
    POSITION_FOUND_TEXT_PLURAL: 'posiciones encontradas',
    PROCESSING_FILE: 'Procesando',
  },
  TeamStructureCard: {
    INVALID_EMAIL_TOOLTIP:
      'No pudimos encontrar el correo electrónico de esta persona, necesita ser arreglado o registrado.',
    WHO: 'Usted',
  },
  CustomToast: {
    PROCESSING_FILE_ERROR:
      'El procesamiento falló. El tipo de archivo es inválido o la plantilla está incompleta.',
    SUCCESS_UPDATED: 'La estructura del equipo ha sido exitosamente actualizada. ',
    CONNECTION_ERROR:
      'La carga falló. Por favor, verifica tu conexión a internet y vuelve a intentar.',
  },
  EllipsisPopover: {
    SET_SPECIALIZATION: 'Establecer especialización',
  },
};

export default esDO;
