import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Upload } from '@admin-portal-shared-components/icons';
import { Alert } from '@hexa-ui/components';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import CSVIcon from '../../assets/icons/CSVIcon';
import FileStore from '../../stores/file/FileStore';
import ImportedFile from '../ImportedFile/ImportedFile';
import InputFileWrapper from './InputFileWrapper/InputFileWrapper';
import DragAndDropInputFileStyles from './DragAndDropInputFile.styles';

interface DragAndDropInputFileProps {
  showFile?: boolean;
  screenSection?: string;
  showToastError?: boolean;
}

/* istanbul ignore next */
const DragAndDropInputFile = ({
  showFile = true,
  screenSection,
  showToastError = false,
}: DragAndDropInputFileProps): JSX.Element => {
  const classes = DragAndDropInputFileStyles();
  const { formatMessage } = useIntl();
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const { file } = useStore(FileStore);

  /* istanbul ignore next */
  const renderInputFileWrapperContent = () => {
    if (dragEnter) {
      return (
        <InputFileWrapper
          dragEnter={dragEnter}
          setDragEnter={setDragEnter}
          screenSection={screenSection}
          setHasError={setHasError}
          showToastError={showToastError}
        >
          <Upload className={classes.uploadIcon} />
          <Typography className={classes.dropFileTitle}>
            {formatMessage({ id: 'DragAndDropInputFile.DROP_FILE_TEXT' })}
          </Typography>
        </InputFileWrapper>
      );
    }

    if (showFile && file && file.fileName) {
      return <ImportedFile hasProgress taller />;
    }

    return (
      <InputFileWrapper
        dragEnter={dragEnter}
        setDragEnter={setDragEnter}
        screenSection={screenSection}
        setHasError={setHasError}
        showToastError={showToastError}
      >
        <CSVIcon />
        <Typography className={classes.uploadCsvTitle}>
          {formatMessage({ id: 'DragAndDropInputFile.UPLOAD_CSV_TEXT' })}
        </Typography>
        <Typography className={classes.dragAndDropTitle}>
          {formatMessage({ id: 'DragAndDropInputFile.DRAG_AND_DROP_TEXT' })}
        </Typography>
      </InputFileWrapper>
    );
  };

  return (
    <>
      {hasError && !showToastError && (
        <Alert
          className={classes.errorAlert}
          type="error"
          message={formatMessage({ id: 'CustomToast.PROCESSING_FILE_ERROR' })}
        />
      )}
      {renderInputFileWrapperContent()}
    </>
  );
};

export default DragAndDropInputFile;
