import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import TeamStructureTitleStyles from './TeamStructureTitle.styles';

interface TeamStructureTitleProps {
  isBetaVersion?: boolean;
}

const TeamStructureTitle = ({ isBetaVersion = false }: TeamStructureTitleProps): JSX.Element => {
  const classes = TeamStructureTitleStyles();
  const { formatMessage } = useIntl();

  return (
    <Typography variant="h1">
      {formatMessage({ id: 'TeamStructureTitle.TEAM_STRUCTURE_TITLE' })}{' '}
      {isBetaVersion && <span className={classes.betaText}>beta</span>}
    </Typography>
  );
};

export default TeamStructureTitle;
