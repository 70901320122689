import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import EmptyStateBox from '../../../../assets/EmptyStateBox';
import NoStructureCardReadOnlyStyles from './NoStructureCardReadOnly.styles';

const NoStructureCardReadOnly = (): JSX.Element => {
  const classes = NoStructureCardReadOnlyStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.wrapper}>
      <EmptyStateBox />
      <Typography className={classes.text} variant="subtitle1">
        {formatMessage({ id: 'NoStructureCardReadOnly.NO_STRUCTURE_CREATED' })}
      </Typography>
    </div>
  );
};

export default NoStructureCardReadOnly;
