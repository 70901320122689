import { TeamStructure } from '@config/types';
import { isFeatureEnabled } from 'grow-shared-services';
import { GROW_ORGANIZATION_ACTIVATION } from '../../config/featureToggles';
import createAndUpdateTeamStructureV3 from '../../services/teamStructure/createAndUpdateTeamStructure/CreateAndUpdateTeamStructure';
import CreateTeamStructure from '../../services/teamStructure/createTeamStructure/CreateTeamStructure';
import UpdateTeamStructure from '../../services/teamStructure/updateTeamStructure/UpdateTeamStructure';

export async function executeUpdateTeamStructure(csvFile: File): Promise<TeamStructure> {
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);
  return isOrganizationEnabled
    ? createAndUpdateTeamStructureV3(csvFile)
    : UpdateTeamStructure(csvFile);
}

export async function executeCreateTeamStructure(csvFile: File): Promise<TeamStructure> {
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);
  return isOrganizationEnabled
    ? createAndUpdateTeamStructureV3(csvFile)
    : CreateTeamStructure(csvFile);
}
