import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { LoadingBuzz } from '@hexa-ui/components';
import TeamStructureStore from '../../stores/teamStructure/TeamStructureStore';
import { getTeamStructureEffect } from '../../stores/teamStructure/TeamStructureEffects';
import NoStructureCard from './components/NoStructureCard/NoStructureCard';
import NoStructureCardReadOnly from './components/NoStructureCardReadOnly/NoStructureCardReadOnly';
import NoStructureContainer from './components/NoStructureContainer/NoStructureContainer';
import TeamStructureHeader from './components/TeamStructureHeader/TeamStructureHeader';
import TeamStructureContainer from './components/TeamStructureContainer/TeamStructureContainer';
import TeamStructureMainPageStyle from './TeamStructureMainPage.styles';
import useIsManager from '../../hooks/roles/useIsManager/useIsManager';
import { removeLocalStorageLeader } from '../../config/utils/functions';

const TeamStructureMainPage = (): JSX.Element => {
  const classes = TeamStructureMainPageStyle();

  const { done, teamStructure } = useStore(TeamStructureStore);
  /* istanbul ignore next */
  const hasStructure = teamStructure?.structure?.length > 0;

  /* istanbul ignore next */
  useEffect(() => {
    getTeamStructureEffect();

    const unwatchGetTeamStructureEffectDone = getTeamStructureEffect.done.watch(() => {
      if (typeof unwatchGetTeamStructureEffectDone === 'function') {
        unwatchGetTeamStructureEffectDone();
      }
    });

    const unwatchGetTeamStructureEffectFail = getTeamStructureEffect.fail.watch(() => {
      removeLocalStorageLeader();
      if (typeof unwatchGetTeamStructureEffectFail === 'function') {
        unwatchGetTeamStructureEffectFail();
      }
    });
  }, []);

  /* istanbul ignore next */
  const isGrowManager = useIsManager();

  if (!done) {
    return (
      <div className={classes.container}>
        <div className={classes.loadingView}>
          <LoadingBuzz size="xxlarge" dataTestId="transition-spinner" />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <TeamStructureHeader />
        {hasStructure ? (
          /* istanbul ignore next */
          <TeamStructureContainer />
        ) : (
          <NoStructureContainer>
            {isGrowManager ? <NoStructureCard /> : <NoStructureCardReadOnly />}
          </NoStructureContainer>
        )}
      </div>
    </div>
  );
};
export default TeamStructureMainPage;
