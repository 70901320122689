import { createStore } from 'effector';
import { initialState } from '../../config/mocks/TeamStructure/TeamStructureState.mocks';
import * as TeamStructureEvents from './TeamStructureEvents';
import {
  getTeamStructureEffect,
  createTeamStructureEffect,
  updateTeamStructureEffect,
} from './TeamStructureEffects';
import { TeamStructureState } from './TeamStructureState';

const TeamStructureStore = createStore(initialState)
  .on(getTeamStructureEffect, (state: TeamStructureState) => ({
    ...state,
    done: false,
    errorToLoad: false,
    loading: true,
  }))
  .on(getTeamStructureEffect.done, (state: TeamStructureState, { result }) => ({
    ...state,
    done: true,
    errorToLoad: false,
    teamStructure: result,
    loading: false,
  }))
  .on(getTeamStructureEffect.fail, (state: TeamStructureState) => ({
    ...state,
    done: true,
    errorToLoad: true,
    loading: false,
  }))
  .on(createTeamStructureEffect, (state: TeamStructureState) => ({
    ...state,
    errorToLoad: false,
    loading: true,
  }))
  .on(createTeamStructureEffect.done, (state: TeamStructureState, { result }) => ({
    ...state,
    errorToLoad: false,
    teamStructure: result,
    loading: false,
  }))
  .on(createTeamStructureEffect.fail, (state: TeamStructureState) => ({
    ...state,
    errorToLoad: true,
    loading: false,
  }))
  .on(updateTeamStructureEffect, (state: TeamStructureState) => ({
    ...state,
    errorToLoad: false,
    loading: true,
  }))
  .on(updateTeamStructureEffect.done, (state: TeamStructureState, { result }) => ({
    ...state,
    errorToLoad: false,
    teamStructure: result,
    loading: false,
  }))
  .on(updateTeamStructureEffect.fail, (state: TeamStructureState) => ({
    ...state,
    errorToLoad: true,
    loading: false,
  }))
  .on(
    TeamStructureEvents.getTeamStructure,
    (state: TeamStructureState, newState: TeamStructureState) => ({
      ...state,
      ...newState,
    }),
  )
  .reset(TeamStructureEvents.resetTeamStructureStore);
export default TeamStructureStore;
