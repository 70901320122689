import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: '100%',
      height: '92.5%',
      margin: '0rem 0em',
      display: 'flex',
      overflow: 'hidden',
      boxShadow: '0rem 0.5rem 1.5rem 0rem rgb(0 0 0 / 16%)',
      paddingTop: '0',
      borderRadius: '24px',
      marginBottom: '0em',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      backgroundColor: palette.background.default,
    },
  }),
);
