import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    wrapper: {
      height: 'calc(95vh - 60px)',
      width: '100vw',
      marginTop: '4px',
    },
    container: {
      height: '100%',
      width: '100%',
      marginTop: '10px',
      paddingLeft: '104px',
      paddingRight: '24px',
      display: 'flex',
      flexDirection: 'column',
    },
    loadingView: {
      left: 'calc(50% - 22px)',
      top: 'calc(50% - 22px)',
      position: 'absolute',
    },
  }),
);
