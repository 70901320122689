import React from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@admin-portal-shared-components/button';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import { useAnalytics } from '../../../../hooks/useAnalytics/useAnalytics';
import { teamStructureImported } from '../../../../Analytics/index';
import ImportedFile from '../../../../components/ImportedFile/ImportedFile';
import ImportConfirmationCardStyles from './ImportConfirmationCard.styles';
import FileStore from '../../../../stores/file/FileStore';
import { resetFileStore, setIsProcessed } from '../../../../stores/file/FileEvents';
import { createTeamStructureEffect } from '../../../../stores/teamStructure/TeamStructureEffects';
import { getFileFromUrl } from '../../../../config/utils/functions';
import CustomToast from '../../../../components/CustomToast/CustomToast';

const ImportConfirmationCard = (): JSX.Element => {
  const classes = ImportConfirmationCardStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { formatMessage } = useIntl();
  const { file } = useStore(FileStore);

  /* istanbul ignore next */
  const handleOnClickCancelBtn = () => {
    resetFileStore(null);
  };

  /* istanbul ignore next */
  const handleOnClickImportBtn = async () => {
    const { fileUrl, fileName } = file;
    const csvfile = await getFileFromUrl(fileUrl, fileName);

    createTeamStructureEffect(csvfile);

    const unwatchCreateTeamStructureEffectDone = createTeamStructureEffect.done.watch(() => {
      window.URL.revokeObjectURL(fileUrl);
      resetFileStore(null);
      setIsProcessed(false);

      CustomToast({
        type: 'success',
        message: formatMessage({ id: 'CustomToast.SUCCESS_UPDATED' }),
      });

      dispatchGenericEvent(teamStructureImported, {
        screen_section: 'No structure yet page',
      });

      /* istanbul ignore next */
      if (typeof unwatchCreateTeamStructureEffectDone === 'function') {
        unwatchCreateTeamStructureEffectDone();
      }
    });

    const unwatchCreateTeamStructureEffectFail = createTeamStructureEffect.fail.watch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any) => {
        const errorCsv = result.error?.response?.data;

        if (errorCsv) {
          CustomToast({
            type: 'error',
            message: formatMessage({ id: 'CustomToast.PROCESSING_FILE_ERROR' }),
          });
        }

        CustomToast({
          type: 'error',
          message: formatMessage({ id: 'CustomToast.CONNECTION_ERROR' }),
        });

        /* istanbul ignore next */
        if (typeof unwatchCreateTeamStructureEffectFail === 'function') {
          unwatchCreateTeamStructureEffectFail();
        }
      },
    );
  };

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} variant="h1">
        {formatMessage({ id: 'ImportConfirmationCard.CONFIRMATION_WARNING' })}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {formatMessage({ id: 'ImportConfirmationCard.SUCCESS_WARNING' })}
      </Typography>
      <div className={classes.importedFileWrapper}>
        <ImportedFile />
      </div>
      <div className={classes.buttonsGroup}>
        <Button
          size="large"
          variant="secondary"
          className={classes.cancelButton}
          onClick={handleOnClickCancelBtn}
        >
          {formatMessage({ id: 'ImportConfirmationCard.CANCEL_BTN_TEXT' })}
        </Button>
        <Button
          size="large"
          data-testid="importButton"
          className={classes.importButton}
          onClick={handleOnClickImportBtn}
        >
          {formatMessage({ id: 'ImportConfirmationCard.IMPORT_BTN_TEXT' })}
        </Button>
      </div>
    </div>
  );
};

export default ImportConfirmationCard;
