import { useHasPermission } from 'admin-portal-shared-services';
import { useCallback } from 'react';

const useIsCoordinator = (): boolean => {
  const hasCampaignsWritePermission = useHasPermission('BeesGrow.Campaign.Write');
  const hasAgentWritePermission = useHasPermission('BeesGrow.Agent.Write');
  // temporarily validate Read  team structure READ permission without Scope created
  const hasTeamStructureReadPermission = !!'BeesGrow.TeamStructure.Write';

  const isCoordinator = useCallback(
    () => hasCampaignsWritePermission && hasAgentWritePermission && hasTeamStructureReadPermission,
    [hasCampaignsWritePermission, hasAgentWritePermission, hasTeamStructureReadPermission],
  );

  return isCoordinator();
};

export default useIsCoordinator;
