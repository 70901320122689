import React from 'react';
import IllustrationFileImg from './Illustration_File@2x.png';

interface ImgProps {
  className?: string;
}

const CsvPaper = ({ className = '' }: ImgProps): JSX.Element => (
  <img className={className} src={IllustrationFileImg} alt="A papersheet with lines" />
);

export default CsvPaper;
