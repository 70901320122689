import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    importButton: {
      fontWeight: 500,
      letterSpacing: 0,
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#1AF07E',
      },
    },
    title: {
      textAlign: 'left',
      fontSize: '32px',
      lineHeight: '40px',
      marginRight: '32px',
    },
    container: {
      width: '580px',
      marginRight: '-32px',
    },
    subtitle: {
      textAlign: 'left',
      width: '580px',
      marginTop: '24px',
    },
    currentStructure: {
      fontSize: '16px',
      marginTop: '24px',
      marginBottom: '0px',
    },
    saveBackup: {
      marginTop: '4px',
      color: palette.secondary.main,
    },
    downloadBackup: {
      marginTop: '8px',
      textDecoration: 'none',
      color: '#0363C4',
      display: 'inline-block',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
      textTransform: 'none',
      padding: 0,
      background: 'none',
      border: 'none',
      font: 'inherit',
      cursor: 'pointer',
      outline: 'inherit',
    },
    uploadFile: {
      marginTop: '24px',
      fontSize: '16px',
      marginBottom: '0px',
    },
    dragDropWrapper: {
      margin: '4px auto 24px auto',
      width: '580px',
    },
  }),
);
