import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  ImportDialog: {
    IMPORT_BTN_TEXT: 'Import',
    IMPORT_STRUCTURE: 'Import structure',
    WARNING_OVERRIDE:
      'You can upload a file with the full structure, this action overrides all current structures.',
    CURRENT_STRUCTURE: 'Current structure',
    SAVE_BACKUP: 'You can save a backup with the current team structure.',
    DOWNLOAD_BACKUP: 'Download backup file',
    UPLOAD_FROM_FILE: 'Update from file',
    ConfirmationModal: {
      MODAL_OVERRIDE: 'Are you sure you want to override the current structure?',
      MODAL_WARNING_A: 'The current structure will be replaced with the new one.',
      MODAL_WARNING_B: "This action can't be undone.",
      CANCEL_BTN_TEXT: 'Cancel',
      CONFIRM_OVERRIDE: 'Override',
    },
  },
  TeamStructureTitle: {
    TEAM_STRUCTURE_TITLE: 'Team structure',
  },
  NoStructureCardReadOnly: {
    NO_STRUCTURE_CREATED: 'No structure created yet.',
  },
  NoStructureCard: {
    NO_STRUCTURE_CREATED: 'No structure created yet!',
    CAN_UPLOAD_FILE: 'You can upload a file with the full structure.',
    UPLOAD_FROM_TEMPLATE: 'Upload the CSV file from our template.',
    DOWNLOAD_TEMPLATE: 'Download template',
  },
  TemplateDownload: {
    UPLOAD_FROM_TEMPLATE: 'Upload the CSV file from our template.',
    DOWNLOAD_TEMPLATE: 'Download template',
  },
  ImportConfirmationCard: {
    CONFIRMATION_WARNING: 'Are you sure to import the team structure?',
    SUCCESS_WARNING: 'File successfully processed and now ready to be imported.',
    CANCEL_BTN_TEXT: 'Cancel',
    IMPORT_BTN_TEXT: 'Import',
  },
  DragAndDropInputFile: {
    DROP_FILE_TEXT: 'Drop file to upload',
    UPLOAD_CSV_TEXT: 'Upload a CSV file',
    DRAG_AND_DROP_TEXT: 'or drag and drop it here',
  },
  ImportedFile: {
    POSITION_FOUND_TEXT: 'position found',
    POSITION_FOUND_TEXT_PLURAL: 'positions found',
    PROCESSING_FILE: 'Processing',
  },
  TeamStructureCard: {
    INVALID_EMAIL_TOOLTIP:
      "We couldn't find this person's email, it needs to be fixed or registered.",
    WHO: 'You',
  },
  CustomToast: {
    PROCESSING_FILE_ERROR:
      'Processing failed. The file type is invalid or the template incomplete.',
    SUCCESS_UPDATED: 'Team structure successfully updated.',
    CONNECTION_ERROR:
      'Upload failed. Please, check your internet connection and try to upload again.',
  },
  EllipsisPopover: {
    SET_SPECIALIZATION: 'Set specialization',
  },
};

export default enUS;
