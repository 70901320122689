import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    toastContainer: {
      width: 'auto',
      height: '48px',
      top: '17px',
      right: '20px',
    },
    toast: {
      borderRadius: '8px',
      color: ' rgba(0,0,0,0.9)',
      '& .Toastify__toast-body>div:last-child': {
        fontFamily: 'Work Sans',
        fontWeight: 400,
        letterSpacing: '0.1px',
        fontSize: '14px',
        marginRight: '40px',
      },
    },
    XCircleIcon: {
      color: 'rgba(0,0,0,0.55)',
      width: '16px',
      height: 'auto',
      marginRight: '6px',
    },
  }),
);
