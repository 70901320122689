import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';
import axios from '../../../config/axios/axiosInstance';
import { getUserInfos } from '../../../config/utils/functions';
import { getApiHost, getV2EndPoint, getVendorIdParam } from '../../host/HostService';
import {
  GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE,
  GROW_ORGANIZATION_ACTIVATION,
} from '../../../config/featureToggles';

export default async function GetTeamStructureBackupFile(structureId: string): Promise<string> {
  const log = useLogService();
  const { vendorId, orgId } = getUserInfos();

  const isMultiContractEnabled = isFeatureEnabled(GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE);
  const isOrganizationEnabled = isFeatureEnabled(GROW_ORGANIZATION_ACTIVATION);

  const vendorIdParam = getVendorIdParam(vendorId, isMultiContractEnabled);

  const getData = () => {
    const url = `${getApiHost()}/grow-team-structure-service`;
    const headers = {};

    if (isOrganizationEnabled) {
      return {
        headers: { orgId },
        url: `${url}/v3/team-structure/download`,
      };
    }

    if (isMultiContractEnabled)
      return {
        headers,
        url: `${url}/grow-team-structure${getV2EndPoint(
          isMultiContractEnabled,
        )}/download-structure${vendorIdParam}`,
      };

    return {
      headers,
      url: `${url}/grow-team-structure/download-structure/${structureId}`,
    };
  };

  const { url, headers } = getData();

  try {
    const response = await axios.get<string>(url, { headers });
    log.info(`Return GetTeamStructureBackupFile from ${url} to our model`, response);
    return response.data;
  } catch (error) {
    log.error(error);
    throw error;
  }
}
