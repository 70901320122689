import React from 'react';
import { InitialDataWrapper } from 'grow-shared-services';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import { ThemeProvider as HubThemeProvider } from '@hexa-ui/theme';
import { XCircle } from '@admin-portal-shared-components/icons';
import { toast, ToastContainer } from 'react-toastify';
import { WRAPPER_PROJECT_NAME_INSTANCE } from './config/constants';
import { useSegmentAnalytics } from './hooks/useSegmentAnalytics/useSegmentAnalytics';
import { EnvConfig, EnvProvider } from './components/EnvProvider/EnvProvider';
import { IntlProvider } from './i18n';
import CustomTheme from './theme';
import Router from './Router';
import ToastfyCustomStyles from './ToastifyOverride.styles';
import 'react-toastify/dist/ReactToastify.min.css';
import './global.css';

const App = (props: EnvConfig): JSX.Element => {
  const { TLP_SEGMENT_KEY } = props;
  const classes = ToastfyCustomStyles();

  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-grow-team-structure-mfe',
    seed: 'bees-hub-grow-team-structure-mfe',
  });

  useSegmentAnalytics(TLP_SEGMENT_KEY);

  return (
    <InitialDataWrapper projectName={WRAPPER_PROJECT_NAME_INSTANCE}>
      <ThemeProvider theme={CustomTheme}>
        <HubThemeProvider theme="grow">
          <EnvProvider env={props}>
            <StylesProvider generateClassName={generateClassName}>
              <ToastContainer
                className={classes.toastContainer}
                toastClassName={classes.toast}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2000}
                hideProgressBar
                draggable={false}
                closeButton={<XCircle className={classes.XCircleIcon} />}
              />
              <IntlProvider>
                <Router />
              </IntlProvider>
            </StylesProvider>
          </EnvProvider>
        </HubThemeProvider>
      </ThemeProvider>
    </InitialDataWrapper>
  );
};

export default App;
