import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    uploadCsvTitle: {
      color: palette.text.secondary,
      fontFamily: 'Work Sans',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '24px',
      marginTop: '13px',
    },
    dragAndDropTitle: {
      color: palette.secondary.main,
      fontFamily: 'Work Sans',
      fontSize: '14px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
      marginTop: '4px',
    },
    dropFileTitle: {
      color: '#0363C4',
      fontFamily: 'Work Sans',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '24px',
      marginTop: '8px',
    },
    uploadIcon: {
      color: '#0363C4',
      width: '40px',
      height: '40px',
    },
    errorAlert: {
      width: '100%',
      marginBottom: '8px',
      letterSpacing: '0.1px',
      lineHeight: '20px',
    },
  }),
);
