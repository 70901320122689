import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import { conditionalClass } from '../../config/utils/functions';
import { useAnalytics } from '../../hooks/useAnalytics/useAnalytics';
import { teamStructureUxButtonClicked } from '../../Analytics/index';
import TemplateDownloadStyles from './TemplateDownload.styles';
import templateFile from './templateFile';

interface TemplateDownloadProps {
  screenSection: string;
  lightText?: boolean;
}

const TemplateDownload = ({ screenSection, lightText }: TemplateDownloadProps): JSX.Element => {
  const classes = TemplateDownloadStyles();
  const { formatMessage } = useIntl();
  const { dispatchGenericEvent } = useAnalytics();

  const handleAnalytics = () => {
    dispatchGenericEvent(teamStructureUxButtonClicked, {
      button_name: 'download-template',
      button_label: formatMessage({ id: 'TemplateDownload.DOWNLOAD_TEMPLATE' }),
      screen_section: screenSection,
      hierarchy: null,
    });
  };

  return (
    <div className={classes.wrapper}>
      <Typography className={conditionalClass(lightText, classes.lightText)} variant="subtitle2">
        {formatMessage({ id: 'TemplateDownload.UPLOAD_FROM_TEMPLATE' })}
      </Typography>
      <CSVLink
        data={templateFile}
        target="_blank"
        filename="TeamStructureTemplate.csv"
        uFEFF={false}
        className={classes.teamStructureTemplate}
        onClick={handleAnalytics}
        data-testid="downloadTemplateButton"
      >
        {formatMessage({ id: 'TemplateDownload.DOWNLOAD_TEMPLATE' })}
      </CSVLink>
    </div>
  );
};

export default TemplateDownload;
