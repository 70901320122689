/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

export type EnvConfig = {
  name: string;
  ENV: string;
  ENV_TYPE: string;
  OPTIMIZELY_KEY: string;
  TLP_SEGMENT_KEY: string;
  europeCountries?: string;
};

const defaultInitialValue: EnvConfig = {
  name: '',
  ENV: '',
  ENV_TYPE: '',
  OPTIMIZELY_KEY: '',
  TLP_SEGMENT_KEY: '',
  europeCountries: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

/* istanbul ignore next */
export const useEnvContext = () => React.useContext(EnvContext);

export const EnvProvider = ({ env, children }: { env: EnvConfig; children: React.ReactNode }) => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
