import React from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { AlertOctagon, ChevronDown, ChevronUp } from '@admin-portal-shared-components/icons';
import { Tooltip } from '@hexa-ui/components';
import { useAnalytics } from '../../../../hooks/useAnalytics/useAnalytics';
import { teamStructureUxButtonClicked } from '../../../../Analytics/index';
import { capitalize, conditionalClass } from '../../../../config/utils/functions';
import { TeamMember } from '../../../../config/types';
import TeamStructureCardStyles from './TeamStructureCard.styles';
import EllipsisPopover from '../../../../components/EllipsisPopover/EllipsisPopover';

interface TeamStructureCardProps {
  person: TeamMember;
  isLeader?: boolean;
  hasSubordinates?: boolean;
  isYou?: boolean;
  changeLeader?: (newLeader: string) => void;
  reportTo?: string;
}

const TeamStructureCard = ({
  person,
  isLeader = false,
  isYou = false,
  hasSubordinates = false,
  changeLeader,
  reportTo,
}: TeamStructureCardProps): JSX.Element => {
  const classes = TeamStructureCardStyles();
  const { dispatchGenericEvent } = useAnalytics();
  const { formatMessage } = useIntl();

  const { fullName, isEmailValid, avatar, positionName, email } = person;

  const hierarchyName = capitalize(positionName);

  const handleClick = () => {
    dispatchGenericEvent(
      teamStructureUxButtonClicked,
      !isLeader
        ? {
            button_name: 'lower-level',
            button_label: 'lower-level',
            screen_section: 'Single Member Card',
            hierarchy: hierarchyName,
          }
        : {
            button_name: 'upper-level',
            button_label: 'upper-level',
            screen_section: 'Single Member Card',
            hierarchy: hierarchyName,
          },
    );
    const newLeader = isLeader ? reportTo : email;
    changeLeader(newLeader);
  };

  return (
    <div
      className={`
        ${classes.wrapper}
        ${conditionalClass(isLeader, classes.wrapperLeader)}
        ${conditionalClass(!isEmailValid, classes.wrapperInvalidEmail)}
        ${conditionalClass(hasSubordinates !== isLeader, classes.wrapperCursor)}
      `}
      data-testid="TeamStructureCard-wrapper"
      {...(hasSubordinates !== isLeader && { onClick: handleClick })}
    >
      {!hasSubordinates && !isLeader && isEmailValid && (
        <EllipsisPopover data-testid="EllipsisPopover" email={email} hierarchy={hierarchyName} />
      )}
      {!isEmailValid && (
        <div className={classes.tooltipDiv} data-testid="alertOctagon-icon">
          <Tooltip
            text={formatMessage({ id: 'TeamStructureCard.INVALID_EMAIL_TOOLTIP' })}
            placement="top"
          >
            <AlertOctagon className={classes.alertOctagon} />
          </Tooltip>
        </div>
      )}
      <div className={classes.avatarWrapper} data-testid="avatarWrapper">
        <Typography className={classes.avatarText}>{avatar.toUpperCase()}</Typography>
      </div>
      {isYou && (
        <div className={classes.avatarYouDiv}>
          <Typography className={classes.avatarYou} data-testid="avatarYou">
            {formatMessage({ id: 'TeamStructureCard.WHO' })}
          </Typography>
        </div>
      )}
      <div className={classes.nameWrapper} data-testid="nameWrapper">
        <Typography className={classes.nameText} title={fullName}>
          {fullName}
        </Typography>
      </div>
      <Typography className={classes.roleWrapper} data-testid="roleWrapper" title={hierarchyName}>
        {hierarchyName}
      </Typography>
      {hasSubordinates !== isLeader &&
        (isLeader ? (
          <ChevronUp
            className={classes.chevronDown}
            data-testid="chevronUp-icon"
            onClick={handleClick}
          />
        ) : (
          <ChevronDown
            className={classes.chevronDown}
            data-testid="chevronDown-icon"
            onClick={handleClick}
          />
        ))}
    </div>
  );
};

export default TeamStructureCard;
