import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import useIsManager from './hooks/roles/useIsManager/useIsManager';
import useIsCoordinator from './hooks/roles/useIsCoordinator/useIsCoordinator';
import { BASE_NAME } from './config/constants';
import TeamStructureMainPage from './pages/teamStructure/TeamStructureMainPage';

const Router = (): JSX.Element => {
  const isGrowManager = useIsManager();
  const isCoordinator = useIsCoordinator();

  const hasPermission = (): boolean => isGrowManager || isCoordinator;

  return (
    <BrowserRouter>
      <Switch>
        {hasPermission() && (
          <>
            <Route
              data-testid="route-team-structure"
              path={`${BASE_NAME}/team-structure`}
              exact
              component={TeamStructureMainPage}
            />
            <Redirect data-testid="redirect-team-structure" to={`${BASE_NAME}/team-structure`} />
          </>
        )}
        <Redirect data-testid="redirect-beesgrow" to={`${BASE_NAME}/beesgrow`} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
