import FileState from './FileState';

export const initialState: FileState = {
  file: {
    fileName: '',
    fileUrl: '',
    fileSize: 0,
    peopleFound: 0,
  },
  isProcessed: false,
};

export const fileNotProcessed: FileState = {
  file: {
    fileName: 'teste',
    fileUrl: 'teste',
    fileSize: 100,
    peopleFound: 0,
  },
  isProcessed: false,
};

export const fileIsProcessed: FileState = {
  file: {
    fileName: 'teste',
    fileUrl: 'teste',
    fileSize: 100,
    peopleFound: 0,
  },
  isProcessed: true,
};
