import { useHasPermission } from 'admin-portal-shared-services';
import { useCallback } from 'react';

const useIsManager = (): boolean => {
  const hasBeesGrowAll = useHasPermission('BeesGrow.All.Write');
  const hasCampaignsWritePermission = useHasPermission('BeesGrow.Campaign.Write');
  const hasAgentWritePermission = useHasPermission('BeesGrow.Agent.Write');
  const hasBlockListWritePermission = useHasPermission('BeesGrow.BlockList.Write');
  const hasMissionManagementWrite = useHasPermission('BeesGrow.Mission.Write');
  const hasTeamStructureWritePermission = useHasPermission('BeesGrow.TeamStructure.Write');

  const isManager = useCallback(
    () =>
      hasBeesGrowAll ||
      (hasCampaignsWritePermission &&
        hasAgentWritePermission &&
        hasBlockListWritePermission &&
        hasMissionManagementWrite &&
        hasTeamStructureWritePermission),
    [
      hasBeesGrowAll,
      hasCampaignsWritePermission,
      hasAgentWritePermission,
      hasBlockListWritePermission,
      hasMissionManagementWrite,
      hasTeamStructureWritePermission,
    ],
  );

  return isManager();
};

export default useIsManager;
