import React from 'react';
import { Typography } from '@material-ui/core';
import { useStore } from 'effector-react';
import { useIntl } from 'react-intl';
import TemplateDownload from '../../../../components/TemplateDownload/TemplateDownload';
import DragAndDropInputFile from '../../../../components/DragAndDropInputFile/DragAndDropInputFile';
import FileStore from '../../../../stores/file/FileStore';
import ImportConfirmationCard from '../ImportConfirmationCard/ImportConfirmationCard';
import NoStructureCardStyles from './NoStructureCard.styles';

const NoStructureCard = (): JSX.Element => {
  const classes = NoStructureCardStyles();
  const { formatMessage } = useIntl();
  const { isProcessed } = useStore(FileStore);

  const renderByIsProcessed = () => {
    if (isProcessed) {
      return <ImportConfirmationCard data-testid="importConfirmationCard" />;
    }

    return (
      <div className={classes.wrapper} data-testid="notProcessed-wrapper">
        <Typography variant="h2">
          {formatMessage({ id: 'NoStructureCard.NO_STRUCTURE_CREATED' })}
        </Typography>
        <Typography variant="subtitle1">
          {formatMessage({ id: 'NoStructureCard.CAN_UPLOAD_FILE' })}
        </Typography>
        <div className={classes.dragDropWrapper}>
          <DragAndDropInputFile showToastError data-testid="dragAndDrop" />
        </div>
        <TemplateDownload data-testid="templateDownload" screenSection="No structure yet page" />
      </div>
    );
  };

  return renderByIsProcessed();
};

export default NoStructureCard;
