/* istanbul ignore file */
import { useAuthenticationService } from 'admin-portal-shared-services';
import { useUserConfigService, UserConfig } from 'grow-shared-services';
import { v4 as guid } from 'uuid';
import { TeamMember } from '../types';
import {
  LOCAL_STORAGE_PORTAL_CONFIG,
  LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL,
  LOCAL_STORAGE_TEAM_STRUCTURE_LEADER,
} from '../constants';

export const isCSV = (fileName: string): boolean => {
  const fileNameLowerCase = fileName.toLowerCase();
  if (fileNameLowerCase.endsWith('.csv')) return true;
  return false;
};

export const formatBytes = (bytes: number, decimals = 0): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getFileFromUrl = async (fileUrl: string, fileName: string): Promise<File> => {
  const blob = await fetch(fileUrl).then((res) => res.blob());
  return new File([blob], fileName);
};

export const setStorageAgentEmail = (email: string): void => {
  const data = JSON.stringify(email);
  return window.localStorage.setItem(LOCAL_STORAGE_TEAM_STRUCTURE_AGENT_EMAIL, data);
};

export const conditionalClass = (condition: boolean, class1: string, class2 = ''): string =>
  condition ? class1 : class2;

export const getPortalConfig = (): Record<string, string> => {
  const portalLocalStorage = localStorage.getItem(LOCAL_STORAGE_PORTAL_CONFIG);

  if (!portalLocalStorage) return undefined;

  return JSON.parse(portalLocalStorage);
};

export const getTokenLocalStorage = (): string => useAuthenticationService().getAuthHeader();

export const getRequestTraceId = (): string => guid();

export const getUserInfos = (): UserConfig => {
  const { data } = useUserConfigService().getStoreValue();

  return { ...data };
};

export const removeDoubleQuotes = (text: string): string => text.replace(/['"]+/g, '');

export const getLocalStorageLeader = (): TeamMember | null => {
  const data = localStorage.getItem(LOCAL_STORAGE_TEAM_STRUCTURE_LEADER);
  if (data) return JSON.parse(data);
  return null;
};

export const setLocalStorageLeader = (teamMember: TeamMember): void => {
  const data = JSON.stringify(teamMember);
  localStorage.setItem(LOCAL_STORAGE_TEAM_STRUCTURE_LEADER, data);
};

export const removeLocalStorageLeader = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_TEAM_STRUCTURE_LEADER);
};

const removeUrlPrefix = (url: string) => url.replace('/api/v1', '');

export const getEuropeClusterOrUrlParam = (url: string): string => {
  const { country } = getUserInfos();
  const europeCountries = localStorage.getItem('europeCountries');

  if (europeCountries?.includes(country)) {
    return process.env.EUROPE_HOST + removeUrlPrefix(url);
  }

  return url;
};
