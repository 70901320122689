import React from 'react';
import NoStructureContainerStyles from './NoStructureContainer.styles';

interface NoStructureContainerProps {
  children: JSX.Element[] | JSX.Element;
}

const NoStructureContainer = ({ children }: NoStructureContainerProps): JSX.Element => {
  const classes = NoStructureContainerStyles();

  return <div className={classes.container}>{children}</div>;
};

export default NoStructureContainer;
