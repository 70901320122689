import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    teamStructureTemplate: {
      textDecoration: 'none',
      color: '#0363C4',
      display: 'inline-block',
      marginTop: '5px',
      textAlign: 'center',
      fontFamily: 'Work Sans',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
    lightText: {
      color: palette.secondary.main,
    },
    wrapper: {
      textAlign: 'center',
    },
  }),
);
