import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(({ palette }) =>
  createStyles({
    container: {
      width: '100%',
      height: '92.5%',
      margin: '0rem 0em',
      display: 'flex',
      overflow: 'hidden',
      boxShadow: '0rem 0.5rem 1.5rem 0rem rgb(0 0 0 / 16%)',
      paddingTop: '0',
      padding: '0',
      borderRadius: '24px',
      marginBottom: '0em',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'flex-start',
      backgroundColor: palette.background.default,
      backgroundSize: '24px 24px',
      backgroundImage: 'radial-gradient(rgba(0,0,0, 0.08) 2px, transparent 0)',
      overflowY: 'scroll',
      position: 'relative',
    },
    arrowUpWrapper: {
      height: '48px',
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    arrowUpButton: {
      '&:hover': {
        transition: 'all 0.3s ease-in-out 0s',
        backgroundColor: '#1AF07E',
      },
    },
    teamMembersWrapper: {
      position: 'absolute',
      top: '296px',
      width: '848px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    teamMembersWrapperTopLevel: {
      top: '72px',
    },
    teamMembersContainer: {
      borderRadius: '24px',
      backgroundColor: '#F2F2F2',
      padding: '16px',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '16px',
      justifyContent: 'center',
      marginBottom: '88px',
    },
    triangleUp: {
      width: 0,
      height: 0,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderBottom: '16px solid #F2F2F2',
    },
  }),
);
