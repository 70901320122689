import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    container: {
      height: '48px',
      marginBottom: '24px',
      position: 'relative',
    },
    buttonsContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '48px',
      width: '268px',
    },
  }),
);
