import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  ImportDialog: {
    IMPORT_BTN_TEXT: 'Importar',
    IMPORT_STRUCTURE: 'Importar estrutura',
    WARNING_OVERRIDE:
      'Você pode carregar um arquivo com a estrutura completa, esta ação substitui todas as estruturas atuais.',
    CURRENT_STRUCTURE: 'Estrutura atual',
    SAVE_BACKUP: 'Você pode salvar um backup com a estrutura do time atual.',
    DOWNLOAD_BACKUP: 'Baixar arquivo de backup',
    UPLOAD_FROM_FILE: 'Atualizar do arquivo',
    ConfirmationModal: {
      MODAL_OVERRIDE: 'Tem certeza de que deseja substituir a estrutura atual?',
      MODAL_WARNING_A: 'A estrutura atual será substituída por uma nova.',
      MODAL_WARNING_B: 'Essa ação não pode ser desfeita.',
      CANCEL_BTN_TEXT: 'Cancelar',
      CONFIRM_OVERRIDE: 'Substituir',
    },
  },
  TeamStructureTitle: {
    TEAM_STRUCTURE_TITLE: 'Estrutura do time',
  },
  NoStructureCardReadOnly: {
    NO_STRUCTURE_CREATED: 'Nenhuma estrutura criada ainda.',
  },
  NoStructureCard: {
    NO_STRUCTURE_CREATED: 'Nenhuma estrutura criada ainda!',
    CAN_UPLOAD_FILE: 'Você pode carregar um arquivo com a estrutura.',
    UPLOAD_FROM_TEMPLATE: 'Carregue o arquivo CSV utilizando nosso template.',
    DOWNLOAD_TEMPLATE: 'Baixar template',
  },
  TemplateDownload: {
    UPLOAD_FROM_TEMPLATE: 'Carregue o arquivo CSV utilizando nosso template.',
    DOWNLOAD_TEMPLATE: 'Baixar template',
  },
  ImportConfirmationCard: {
    CONFIRMATION_WARNING: 'Tem certeza que deseja importar a estrutura do time?',
    SUCCESS_WARNING: 'Arquivo processado com sucesso e pronto para ser importado.',
    CANCEL_BTN_TEXT: 'Cancelar',
    IMPORT_BTN_TEXT: 'Importar',
  },
  DragAndDropInputFile: {
    DROP_FILE_TEXT: 'Solte o arquivo para carregar',
    UPLOAD_CSV_TEXT: 'Carregue um arquivo CSV',
    DRAG_AND_DROP_TEXT: 'ou arraste e solte aqui',
  },
  ImportedFile: {
    POSITION_FOUND_TEXT: 'posição encontrada',
    POSITION_FOUND_TEXT_PLURAL: 'posições encontradas',
    PROCESSING_FILE: 'Processando',
  },
  TeamStructureCard: {
    INVALID_EMAIL_TOOLTIP:
      'Não encontramos o e-mail dessa pessoa, ele precisa ser corrigido ou cadastrado.',
    WHO: 'Você',
  },
  CustomToast: {
    PROCESSING_FILE_ERROR:
      'Falha no processamento. O tipo de arquivo é inválido ou o template está incompleto.',
    SUCCESS_UPDATED: 'Estrutura do time atualizada com sucesso.',
    CONNECTION_ERROR:
      'Falha no carregamento. Verifique sua conexão com a internet e tente novamente.',
  },
  EllipsisPopover: {
    SET_SPECIALIZATION: 'Definir especialização',
  },
};

export default ptBR;
