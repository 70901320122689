import { useLogService } from 'admin-portal-shared-services';
import { isFeatureEnabled } from 'grow-shared-services';
import axios from '../../../config/axios/axiosInstance';
import { TeamStructure } from '../../../config/types';
import { getUserInfos } from '../../../config/utils/functions';
import { getApiHost, getV2EndPoint, getVendorIdParam } from '../../host/HostService';
import { GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE } from '../../../config/featureToggles';

const SERVICE = 'grow-team-structure-service';
const PATH = 'grow-team-structure';
const ENDPOINT = 'upload-structure';

export default async function UpdateTeamStructure(csvFile: File): Promise<TeamStructure> {
  const log = useLogService();
  const { vendorId } = getUserInfos();

  const isMultiContractEnabled = isFeatureEnabled(GROW_ADMIN_MULTICONTRACT_TEAM_STRUCTURE);

  const vendorIdParam = getVendorIdParam(vendorId, isMultiContractEnabled);

  const url = `${getApiHost()}/${SERVICE}/${PATH}${getV2EndPoint(
    isMultiContractEnabled,
  )}/${ENDPOINT}${vendorIdParam}`;

  const formData = new FormData();
  formData.append('file', csvFile);

  try {
    const response = await axios.post<TeamStructure>(url, formData);
    log.info(`Return UpdateTeamStructure from ${url} to our model`, response);
    return response.data;
  } catch (error) {
    log.error(error);
    throw error;
  }
}
