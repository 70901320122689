import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    actionsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '16px',
    },
    container: {
      width: '384px',
    },
    title: {
      textAlign: 'left',
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: '24px',
    },
    text: {
      textAlign: 'left',
      fontSize: '16px',
      margin: '0px',
    },
  }),
);
